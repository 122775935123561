import { Injectable } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { NavigationEnd, NavigationStart, Router } from "@angular/router"
import { BehaviorSubject, Observable } from "rxjs"
import { filter, map, shareReplay } from "rxjs/operators"
import { Location } from "@angular/common"

const TITLESANDPATHS = [
  { title: "Items", paths: ["items"] },
  { title: "Forms Builder", paths: ["formsbuilder", "templatecreator"] },
  { title: "Profile", paths: ["profile"] },
  { title: "Page not Found", paths: ["404"] },
  { title: "Login", paths: ["login"] },
  { title: "Drawings", paths: ["Drawings"] },
  { title: "Reports", paths: ["fieldreports", "legacyreports", "reports"] },
  { title: "Projects", paths: ["", "projects"] },
  { title: "Invitations", paths: ["invitations"] },
  { title: "Company", paths: ["company"] },
  { title: "Registration", paths: ["registration", "register"] },
  { title: "Recover Password", paths: ["passwordrecovery"] },
  { title: "User Activation", paths: ["useractivation"] },
  { title: "Reset Password", paths: ["passwordreset"] },
  { title: "Email Verification", paths: ["emailverification"] },
]

@Injectable()
export class NavigationService {
  private topBar = new BehaviorSubject<boolean>(true)
  private sideNav = new BehaviorSubject<boolean>(true)
  private tabBar = new BehaviorSubject<boolean>(true)

  private showAdvertisement = new BehaviorSubject<boolean>(true)

  showTopbar$ = this.topBar.asObservable()
  showSideNav$ = this.sideNav.asObservable()
  showTabBar$ = this.tabBar.asObservable()

  showAdvertisement$ = this.showAdvertisement.asObservable()

  public pathComponents$: Observable<string[]> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((_) =>
      (this.location && this.location.path ? this.location.path() || "" : "")
        .split("/")
        .map((c) => c.trim())
        .filter((it) => it)
    ),
    shareReplay(1)
  )

  constructor(private router: Router, private location: Location, private titleService: Title) {
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => this.setWebPageTitle(e.urlAfterRedirects))

    this.router.events
      .pipe(filter((e): e is NavigationStart => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => this.listenToRouteChanges(e))
  }

  public hideTopAndSideBar() {
    this.topBar.next(false)
    this.sideNav.next(false)
  }

  private listenToRouteChanges(routeChange: NavigationStart) {
    const { url } = routeChange

    NavigationService.hideNavElement(
      [
        "/404",
        "404",
        "/login",
        "/drawings/",
        "/reports/",
        "registration",
        "/invitations/",
        "password",
        "emailverification",
        "authaction",
        "useractivation",
        "legacyReports",
        "fieldReports",
        "templateCreator",
        "formsBuilder",
        "/projects/new",
        "openTemplates",
        "openReports",
        "forms/builder",
        "/chat",
      ],
      url,
      this.sideNav
    )
    NavigationService.hideNavElement(
      ["/drawings/", "/reports/", "legacyReports", "fieldReports", "/projects/new", "/chat"],
      url,
      this.tabBar
    )
    NavigationService.hideNavElement(
      ["fieldReports", "/drawings/", "/legacyReports/", "/404", "404", "/projects/new", "/login", "openTemplates", "openReports", "/chat"],
      url,
      this.topBar
    )
    NavigationService.showElement(["projects", "company"], url, this.showAdvertisement)
  }

  private setWebPageTitle(inputURL: string) {
    const path = inputURL
      .toLocaleLowerCase()
      .replace(/^\/+|\/+$/, "") // remove leading and trailing '/'
      .split("/")

    const [firstPathItem] = path

    let title = "Checkd Dashboard"

    // special case
    if (path.length === 1 && firstPathItem === "items") {
      title = "My Items"
    } else if (firstPathItem) {
      title = (TITLESANDPATHS.find((x) => x.paths.includes(firstPathItem)) || { title }).title
    }

    this.titleService.setTitle(title)
  }

  private static hideNavElement(targetUrls: string[], currentUrl: string, navElement: BehaviorSubject<boolean>) {
    if (NavigationService.findOccurrence(targetUrls, currentUrl)) {
      navElement.next(false)
    } else {
      navElement.next(true)
    }
  }

  // Strict checks allowedUrls
  private static showElement(allowedUrls: string[], currentUrl: string, element: BehaviorSubject<boolean>) {
    const occurrenceFound: boolean = NavigationService.findOccurrence(allowedUrls, currentUrl.split("/").slice(-1)[0])

    if (occurrenceFound) {
      element.next(true)
    } else {
      element.next(false)
    }
  }

  private static findOccurrence(searchArray: string[], targetString: string): boolean {
    for (const searchStr of searchArray) {
      if (targetString.includes(searchStr)) {
        return true
      }
    }

    return false
  }

  goToProfilePage() {
    this.router.navigate(["/profile"])
  }

  goToLoginPage() {
    this.router.navigate(["/login"])
  }

  goToOpenReportReceiptPage(openReportUid: string) {
    return this.router.navigate([`/openReports/${openReportUid}/receipt`])
  }
}

import { Injectable } from "@angular/core"
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from "rxjs"
import { Item } from "@models/common/item"
import { map, share } from "rxjs/operators"
import { BimsyncIFCCombinedData } from "../models/bimsync.interface"

export interface IBimsyncProductTreeData {
  name: string
  value?: any
  children?: IBimsyncProductTreeData[]
}

@Injectable({
  providedIn: "root",
})
export class BimsyncViewService {
  currentItems$ = new BehaviorSubject<Item[]>([])
  currentItemUid$ = new Subject<string>()

  currentModelId$ = new ReplaySubject<string>()

  currentModelItems$: Observable<Item[]> = combineLatest([this.currentModelId$, this.currentItems$]).pipe(
    map(([id, items]) => items.filter((item) => item.bimsyncModelData.modelId === id))
  )

  constructor() {}

  public convertBimsyncProductDataToTreeData(bimsyncProduct: BimsyncIFCCombinedData): any {
    if (bimsyncProduct == null) {
      return null
    }

    const attributes: any = Object.keys(bimsyncProduct.attributes || []).map((k) => ({
      name: k,
      children: this.convertBimsyncProductDataToTreeData(bimsyncProduct.attributes[k] as BimsyncIFCCombinedData),
    }))
    const propertySets: any = Object.keys(bimsyncProduct.propertySets || []).map((k) => ({
      name: k,
      children: this.convertBimsyncProductDataToTreeData(bimsyncProduct.propertySets[k] as BimsyncIFCCombinedData),
    }))
    const properties: any = Object.keys(bimsyncProduct.properties || []).map((k) => ({
      name: k,
      children: this.convertBimsyncProductDataToTreeData(bimsyncProduct.properties[k] as BimsyncIFCCombinedData),
    }))
    const quantitySets: any = Object.keys(bimsyncProduct.quantitySets || []).map((k) => ({
      name: k,
      children: this.convertBimsyncProductDataToTreeData(bimsyncProduct.quantitySets[k] as BimsyncIFCCombinedData),
    }))

    const materials: any = [].concat(
      ...(bimsyncProduct.materials || []).map((it) => this.convertBimsyncProductDataToTreeData(it as BimsyncIFCCombinedData))
    )

    const enumerationValues: any = [].concat(
      ...(bimsyncProduct.enumerationValues || []).map((it) => this.convertBimsyncProductDataToTreeData(it as BimsyncIFCCombinedData))
    )

    const listValues: any = [].concat(
      ...(bimsyncProduct.listValues || []).map((it) => this.convertBimsyncProductDataToTreeData(it as BimsyncIFCCombinedData))
    )

    return [
      bimsyncProduct.objectId ? { name: "Object ID", value: bimsyncProduct.objectId } : null,
      bimsyncProduct.ifcType ? { name: "IFC Type", value: bimsyncProduct.ifcType } : null,
      bimsyncProduct.revisionId ? { name: "Revision ID", value: bimsyncProduct.revisionId } : null,
      bimsyncProduct.description ? { name: "Description", value: bimsyncProduct.description } : null,

      bimsyncProduct.type ? { name: "Type", value: bimsyncProduct.type } : null,
      bimsyncProduct.unit ? { name: "Unit", value: bimsyncProduct.unit } : null,
      bimsyncProduct.value ? { name: "Value", value: bimsyncProduct.value } : null,

      bimsyncProduct.nominalValue
        ? {
            name: "Nominal Value",
            children: this.convertBimsyncProductDataToTreeData(bimsyncProduct.nominalValue as BimsyncIFCCombinedData),
          }
        : null,
      bimsyncProduct.lowerBoundValue
        ? {
            name: "Lower Bound Value",
            children: this.convertBimsyncProductDataToTreeData(bimsyncProduct.lowerBoundValue as BimsyncIFCCombinedData),
          }
        : null,
      bimsyncProduct.upperBoundValue
        ? {
            name: "Upper Bound Value",
            children: this.convertBimsyncProductDataToTreeData(bimsyncProduct.upperBoundValue as BimsyncIFCCombinedData),
          }
        : null,
      bimsyncProduct.propertyReference
        ? {
            name: "Property Reference",
            children: this.convertBimsyncProductDataToTreeData(bimsyncProduct.propertyReference as BimsyncIFCCombinedData),
          }
        : null,
      enumerationValues.length > 0 ? { name: "Enumeration Values", children: enumerationValues } : null,
      listValues.length > 0 ? { name: "List Values", children: listValues } : null,

      attributes.length > 0 ? { name: "Attributes", children: attributes } : null,
      propertySets.length > 0 ? { name: "Property Sets", children: propertySets } : null,
      properties.length > 0 ? { name: "Properties", children: properties } : null,
      quantitySets.length > 0 ? { name: "Quantity Sets", children: quantitySets } : null,
      materials.length > 0 ? { name: "Materials", children: materials } : null,
    ].filter((it) => it)
  }
}

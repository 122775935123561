<mat-list>
  <mat-list-item>
    <span class="mr-auto">Go to FORMS builder</span>
    <button mat-stroked-button class="ml-2 mr-1" [routerLink]="'/formsBuilder'">old</button>
    <button mat-stroked-button [routerLink]="'/forms/builder'">new</button>
  </mat-list-item>

  <mat-divider></mat-divider>

  <mat-list-item>
    <span class="mr-auto">Public Template Library</span>
    <button mat-stroked-button class="ml-2" [routerLink]="'/forms/public-template-library'">Go</button>
  </mat-list-item>
</mat-list>

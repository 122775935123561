import { Injectable } from "@angular/core"
import { Observable, Subject } from "rxjs"
import { Item } from "@models/common/item"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"
import { filter, map, share, shareReplay, switchMap } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class TopicItemService {
  private _currentItem$ = new Subject<Item>()
  public currentItem$: Observable<Item> = this._currentItem$.pipe(shareReplay({ bufferSize: 1, refCount: true }))

  modelData$: Observable<any> = this.currentItem$.pipe(
    filter((item) => item.itemType === "TOPIC_ITEM"),
    map((topicItem) => topicItem.bimsyncModelData)
  )

  objectData$: Observable<any> = this.modelData$.pipe(
    switchMap((modelData) => this.bimsyncApiV2Service.v2_getObjectInfo(modelData.projectId, modelData.objectId))
  )

  public setCurrentItem(item: Item) {
    this._currentItem$.next(item)
  }

  constructor(private bimsyncApiV2Service: BimsyncApiV2Service) {
    this.modelData$.subscribe((modelData) => {
      this.bimsyncApiV2Service.currentBimsyncProject$.next({ id: modelData.projectId })
      this.bimsyncApiV2Service.currentBimsyncProjectModel$.next({ id: modelData.modelId })
    })
  }
}

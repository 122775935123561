<!--<checkd-advertisement-->
<!--  *ngIf="navigationService.showAdvertisement$ | async"-->
<!--  fxHide.lt-lg>-->
<!--</checkd-advertisement>-->

<div class="dashboard">
  <checkd-top-bar></checkd-top-bar>
  <mat-sidenav-container>
    <mat-sidenav
      #snav
      class="sidenav"
      fixedInViewport
      [attr.role]="(sideNavService.isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(sideNavService.isHandset$ | async) ? 'over' : 'side'"
      [opened]="(navigationService.showSideNav$ | async) && (sideNavService.isHandset$ | async) === false"
    >
      <div style="height: 100%" fxLayout="column">
        <mat-nav-list
          *ngIf="{
            userCompanyRoles: userService.currentUserCompanyRole$ | async,
            currentCompany: userService.currentCompany$ | async
          } as subs"
        >
          <div class="data-container__menu-section">
            <h2 class="data-container__title">PERSONAL</h2>
            <ul class="data-container__list-container">
              <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/items" class="data-container__menu-link">
                  <img src="./assets/icons/duotone/hexagon-check.svg" alt="hexagon check icon" class="icon" />
                  <span>My Items</span>
                </a>
              </li>
              <!-- Chat ready to use. We need to implement the badge which could come from getstream -->
              <!-- <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/chat" class="data-container__menu-link">
                  <img src="./assets/icons/duotone/comments.svg" alt="hexagon check icon" class="icon" />
                  <span>Chat</span>
                </a>
              </li> -->
            </ul>
          </div>
          <div class="data-container__menu-section">
            <h2 class="data-container__title">ORGANIZATION</h2>
            <ul class="data-container__list-container">
              <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/projects" class="data-container__menu-link">
                  <img src="./assets/icons/duotone/briefcase.svg" alt="briefcase icon" class="icon" />
                  <span>Projects</span>
                </a>
              </li>
              <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                <a routerLink="/company" class="data-container__menu-link">
                  <img src="/assets/icons/duotone/building.svg" alt="company icon" class="icon" />
                  <span>My Company</span>
                </a>
              </li>
              <li
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }"
                *ngIf="currentCompanyHasCompanyInsightsFeature(subs.currentCompany) && userIsCompanyAdmin(subs.userCompanyRoles)"
              >
                <a routerLink="/insights" class="data-container__menu-link">
                  <img src="/assets/icons/duotone/chart-line-up.svg" alt="insights icon" class="icon" />
                  <span>Insights</span>
                </a>
              </li>
            </ul>
          </div>

          <ng-container *ngIf="shouldDisplayFormsMenuInSidebar$ | async">
            <div class="data-container__menu-section">
              <h2 class="data-container__title">FORMS</h2>
              <ul class="data-container__list-container">
                <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                  <a routerLink="/forms/company" class="data-container__menu-link">
                    <img src="/assets/icons/duotone/clipboard.svg" alt="insights icon" class="icon" />
                    <span>Company Templates</span>
                  </a>
                </li>
                <li
                  *ngIf="currentCompanyHasFormsFeature$ | async"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <a routerLink="/forms/builder" class="data-container__menu-link">
                    <img src="/assets/icons/duotone/grid-2-plus.svg" alt="insights icon" class="icon" />
                    <span>Forms Builder</span>
                  </a>
                </li>

                <li
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                  *ngIf="subs.currentCompany.isTemplatePublisher"
                >
                  <a routerLink="/forms/member-companies" class="data-container__menu-link">
                    <img src="/assets/icons/duotone/list.svg" alt="insights icon" class="icon" />
                    <span>Subscriber List</span>
                  </a>
                </li>
                <li [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                  <a routerLink="/forms/library" class="data-container__menu-link">
                    <img src="/assets/icons/duotone/books.svg" alt="insights icon" class="icon" />
                    <span>Forms Library</span>
                  </a>
                </li>
              </ul>
            </div>
          </ng-container>
        </mat-nav-list>

        <!-- Subfield logo, uncomment if we want to use it in the future -->
        <!-- <div
              class="company-container sidenav-subfield-color sidenav-subfield-shadow flex-bottom"
              fxLayout="row"
              fxLayoutAlign="start center"
              [ngStyle]="{ 'background-image': 'url(' + (currentCompanyLogo$ | async) + ')' }"
            ></div> -->
      </div>
    </mat-sidenav>
    <mat-sidenav-content [ngStyle]="{ height: (navigationService.showTopbar$ | async) ? 'calc(100vh - 48px)' : '100vh' }">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

import { DragDropModule } from "@angular/cdk/drag-drop"
import { HttpClientModule } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { FlexLayoutModule, FlexModule } from "@angular/flex-layout"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button"
import { MatDividerModule } from "@angular/material/divider"
import { MatIconModule } from "@angular/material/icon"
import { MatSidenavModule } from "@angular/material/sidenav"
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar"
import { MatToolbarModule } from "@angular/material/toolbar"
import { RouterModule, Routes } from "@angular/router"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"
import { ScriptLoaderService } from "@services/script-loader.service"
import { SnackbarService } from "@services/snackbar.service"
import { AngularSplitModule } from "angular-split"
import { BimsyncAuthenticationComponent } from "./components/bimsync-authentication/bimsync-authentication.component"
import { BimsyncModelInfoComponent } from "./components/bimsync-model-info/bimsync-model-info.component"
import { BimsyncModelMenuComponent } from "./components/bimsync-model-menu/bimsync-model-menu.component"
import { BimsyncProjectInfoComponent } from "./components/bimsync-project-info/bimsync-project-info.component"
import { BimsyncProjectListComponent } from "./components/bimsync-project-list/bimsync-project-list.component"
import { BimsyncStoreyListComponent } from "./components/bimsync-storey-list/bimsync-storey-list.component"
import { BimsyncViewer2dComponent } from "./components/bimsync-viewer2d/bimsync-viewer2d.component"
import { BimsyncViewer3dComponent } from "./components/bimsync-viewer3d/bimsync-viewer3d.component"
import { BimsyncViewComponent } from "./views/bimsync-view/bimsync-view.component"
import { CheckdCommonModule, CheckdMaterialModule } from "@checkd-ui"
import { ModelSelectionDialogComponent } from "./dialogs/model-selection-dialog/model-selection-dialog.component"
import { BimsyncAppViewComponent } from "./views/bimsync-app-view/bimsync-app-view.component"
import { MatTreeModule } from "@angular/material/tree"
import { BimsyncObjectTreeComponent } from "./components/bimsync-object-tree/bimsync-object-tree.component"
import { BimsyncActionBarComponent } from "./components/bimsync-action-bar/bimsync-action-bar.component"
import { GalleryModule } from "@ks89/angular-modal-gallery"
import { ButtonModule } from "app/dashboard/next-ui/button/button.module"

const routes: Routes = [
  { path: "bimsync", component: BimsyncViewComponent },
  { path: "projects/:projectId/bimsyncProject/:bimsyncProjectId/models/:bimsyncModelId", component: BimsyncViewComponent },
]

@NgModule({
  declarations: [
    BimsyncViewComponent,
    BimsyncAuthenticationComponent,
    BimsyncProjectListComponent,
    BimsyncProjectInfoComponent,
    BimsyncModelMenuComponent,
    BimsyncModelInfoComponent,
    BimsyncStoreyListComponent,
    BimsyncViewer2dComponent,
    BimsyncViewer3dComponent,
    ModelSelectionDialogComponent,
    BimsyncAppViewComponent,
    BimsyncObjectTreeComponent,
    BimsyncActionBarComponent,
  ],
  exports: [
    BimsyncViewComponent,
    BimsyncAppViewComponent,
    ModelSelectionDialogComponent,
    BimsyncViewer2dComponent,
    BimsyncViewer3dComponent,
    BimsyncObjectTreeComponent,
  ],
  imports: [
    CheckdCommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FlexModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    DragDropModule,
    CheckdMaterialModule,
    MatTreeModule,
    AngularSplitModule,
    GalleryModule,
    ButtonModule,
  ],
  providers: [ScriptLoaderService, BimsyncApiV2Service, SnackbarService],
})
export class BimsyncModule {}

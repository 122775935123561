import { Location } from "@angular/common"
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core"
import { MatLegacyTabGroup as MatTabGroup } from "@angular/material/legacy-tabs"
import { ActivatedRoute, Router } from "@angular/router"
import { DisabledOverlayService } from "@checkd-ui"
import {
  COLLECTIONS,
  Company,
  Drawing,
  Invitation,
  Item,
  LegacyTemplate,
  Person,
  Project,
  ProjectOwnerRole,
  Relation,
  Role,
  SortingDirection,
} from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import {
  CompanyService,
  InvitationService,
  NavigationService,
  ProjectService,
  RelationService,
  ReportService,
  RoleHandlerService,
  TimelineService,
  UserService,
} from "@services"
import { PermissionsService } from "@services/permissions.service"
import { ScriptLoaderService } from "@services/script-loader.service"
import { orderByCreatedAt } from "@services/utilities"
import { combineLatest, EMPTY, finalize, from as observableFrom, Observable, Subscription } from "rxjs"
import { combineLatestWith, delay, map, mergeMap, shareReplay, switchMap, take, tap } from "rxjs/operators"
import { FeatureCheckerService } from "../../features/feature-checker/services/feature-checker.service"
import { OpenReport } from "../../reports/models/open-report"
import { OpenReportService } from "../../reports/services/open-report.service"
import { ProjectReportsService } from "../project-reports/project-reports.service"
import { ProjectViewService } from "./project-view.service"

export interface IProjectMembersWithRoles {
  member: Person
  role: Role
}

@Component({
  selector: "app-project-view",
  templateUrl: "./project-view.component.html",
  styleUrls: ["./project-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectViewComponent implements OnInit, OnDestroy, AfterViewInit {
  currentUser$: Observable<Person> = this.userService.currentUser$
  currentCompany$: Observable<Company> = this.userService.currentCompany$
  currentUserCompanyRole$: Observable<Role> = this.userService.currentUserCompanyRole$

  projectUid$: Observable<string> = this.route.paramMap.pipe(
    // @ts-ignore
    map((paramMap) => paramMap.get("projectId")),
    take(1)
  )

  tabName$: Observable<string> = this.route.paramMap.pipe(
    // @ts-ignore
    map((paramMap) => paramMap.get("tabName")),
    take(1)
  )

  project$: Observable<Project> = this.projectUid$.pipe(
    switchMap((uid) => (uid ? this.projectService.listenToUid(uid) : EMPTY)),
    tap((_) => (this.projectReady = true)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  currentUserProjectRole$: Observable<Role> = combineLatest([this.currentUser$, this.project$]).pipe(
    switchMap((userAndProject) => observableFrom(this.roleHandlerService.getPersonProjectRole(userAndProject[0], userAndProject[1])))
  )

  projectMembers$: Observable<Person[]> = this.project$.pipe(
    switchMap((project) => this.projectService.listenToPeople(project)),
    tap((_) => (this.projectPeopleReady = true)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly currentProjectDrawings$: Observable<Drawing[]> = this.projectService.currentProjectDrawings$.pipe(
    tap((_) => (this.projectDrawingsReady = true)),
    take(1)
  )

  projectMembersWithRoles$: Observable<IProjectMembersWithRoles[]> = combineLatest([this.projectMembers$, this.project$]).pipe(
    switchMap(([members, project]) =>
      combineLatest(members.map((member) => this.roleHandlerService.listenToUserProjectRole(member, project))).pipe(
        map((roles) => roles.map((role, i) => ({ role, member: members[i] })))
      )
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly projectOwnersAndAdmins$: Observable<Person[]> = this.projectMembersWithRoles$.pipe(
    map((it) => this.filterProjectMembersWithRolesByLabels(it, [Relation.LABELS.OWNER, Relation.LABELS.ADMINISTRATOR])),
    map((it) => it.map((value) => value.member))
  )

  readonly projectOwners$: Observable<Person[]> = this.projectMembersWithRoles$.pipe(
    map((it) => this.filterProjectMembersWithRolesByLabels(it, [Relation.LABELS.OWNED_BY])),
    map((it) => it.map((value) => value.member))
  )

  private filterProjectMembersWithRolesByLabels(
    membersWithRoles: IProjectMembersWithRoles[],
    wantedLabels: string[]
  ): IProjectMembersWithRoles[] {
    return membersWithRoles.filter((it) => it.role.labels.some((label) => wantedLabels.includes(label)))
  }

  projectItems$: Observable<Item[]> = this.projectService.currentProjectItemsNew$.pipe(tap((it) => (this.projectItemsReady = true)))

  projectEditorRoles$: Observable<Role[]> = this.project$.pipe(
    switchMap((project) =>
      this.projectMembers$.pipe(
        map((people) => {
          return people.map((person) => observableFrom(this.roleHandlerService.getPersonProjectRole(person, project)))
        }),
        mergeMap((it) => this.relationService.combineLatestOrEmptyList(it)),
        map((roles: any[]) => roles.filter((role: Role) => role.canUpdateTargetRelations(COLLECTIONS.PEOPLE)))
      )
    )
  )

  projectOwnerRoles$: Observable<Role[]> = this.projectEditorRoles$.pipe(
    map((editorRoles) => editorRoles.filter((editorRole) => editorRole instanceof ProjectOwnerRole))
  )

  pendingProjectInvitations$: Observable<Invitation[]> = this.project$.pipe(
    switchMap((project) => this.invitationService.listenToPendingInvitationsFor(project))
  )

  projectOpenReports$: Observable<OpenReport[]> = this.projectUid$.pipe(
    switchMap((uid) => this.foo.listenToOpenReportsForProject(uid)),
    map((reports) => orderByCreatedAt(reports, SortingDirection.DESC))
  )

  readonly projectReports$ = this.projectViewService.allProjectReports$.pipe(
    combineLatestWith(this.projectViewService.projectUserReports$),
    tap((_) => (this.projectReportsReady = true)),
    finalize(() => (this.projectReportsReady = false)),
    map(([allReports, myReports]) => ({ allReports, myReports }))
  )

  projectCompany$: Observable<Company> = this.project$.pipe(
    switchMap((project) => this.projectService.listenToCompany(project)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  projectCompanyTemplates$ = this.projectCompany$.pipe(
    switchMap((company) => this.companyService.listenToTemplates(company)),
    map((templates) => templates.filter((template) => !template.inactive && !template.isDraft))
  )

  currentCompanyMembers: Person[] = []
  currentCompanyAssociates: Person[] = []

  subscriptions: Subscription[]

  @ViewChild("tabs") tabs: MatTabGroup

  selectedTab: string = "drawings"
  projectTemplates: LegacyTemplate[] = []
  currentCompanyLegacyTemplates: LegacyTemplate[]
  projectReports: GeneralReport[] = []
  projectMembers: Person[] = []

  public isLoadingProjectItems$: Observable<boolean> =
    // Hack: Adding delay to force change detection to next cycle
    this.projectService.isLoadingProjectItems$.pipe(delay(0))

  projectItemsReady: boolean = false
  projectReportsReady: boolean = false
  projectDrawingsReady: boolean = false
  projectPeopleReady: boolean = false
  projectReady: boolean = false

  get isReady() {
    return (
      this.projectReady &&
      ((this.isSelectedTabContent("items") && this.projectItemsReady) ||
        (this.isSelectedTabContent("reports") && this.projectReportsReady) ||
        (this.isSelectedTabContent("drawings") && this.projectDrawingsReady) ||
        (this.isSelectedTabContent("people") && this.projectPeopleReady) ||
        this.isSelectedTabContent("gallery") ||
        this.isSelectedTabContent("info"))
    )
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectViewService: ProjectViewService,
    private relationService: RelationService,
    public projectService: ProjectService,
    private roleHandlerService: RoleHandlerService,
    private permissionsService: PermissionsService,
    private reportService: ReportService,
    private userService: UserService,
    private companyService: CompanyService,
    private location: Location,
    public navigationService: NavigationService,
    private invitationService: InvitationService,
    private _cdr: ChangeDetectorRef,
    private disabledOverlay: DisabledOverlayService,
    private timelineService: TimelineService,
    public projectReportsService: ProjectReportsService,
    private scriptLoader: ScriptLoaderService,
    private featureCheckerService: FeatureCheckerService,
    private foo: OpenReportService
  ) {}

  ngOnInit() {
    this.setupSubscriptions()
    this._cdr.detectChanges()
    this.loadScripts()
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.tabName$.subscribe((tabName) => {
        if (tabName != null) {
          this.setSelectedTab(tabName)
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  async loadScripts() {
    try {
      await this.scriptLoader.load("jquery-2.1.1")
      await this.scriptLoader.load("bimsync-viewer")

      await this.scriptLoader.load("bimsync-viewer-2d")

      // this.scriptsLoaded = true
    } catch (err) {
      console.error(err)
    }
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.project$.subscribe((project) => {
        this.projectService.setCurrentProject(project.uid)
        if (project.lockedByCheckd) {
          this.showDisabledOverlay(project)
        }
      }),
      this.projectMembers$.subscribe((members) => (this.projectMembers = members)),

      this.tabName$.pipe(take(1)).subscribe((tabName) => {
        if (tabName != null) {
          this.setSelectedTab(tabName)
        }
      }),
      this.userService.currentCompanyMembers$.subscribe((members) => (this.currentCompanyMembers = members)),
      this.currentCompany$.pipe(switchMap((company) => this.companyService.listenToAssociatedPeople(company))).subscribe((associates) => {
        this.currentCompanyAssociates = associates
      }),

      this.project$.pipe(switchMap((project) => this.projectViewService.listenToProjectTemplates(project))).subscribe((templates) => {
        this.projectTemplates = templates
      }),
      this.currentCompany$
        .pipe(
          switchMap((company) => this.companyService.listenToTemplates(company)),
          map((templates) => templates.filter((template) => !template.inactive && !template.isDraft))
        )
        .subscribe((templates) => (this.currentCompanyLegacyTemplates = templates)),

      this.currentProjectDrawings$.subscribe(),
    ]
  }

  isSelectedTabContent(tabName: string) {
    return this.selectedTab === tabName
  }

  showDisabledOverlay(project: Project) {
    const ref = this.disabledOverlay.openProjectLockedOverlay(project)
  }

  setSelectedTab(tabName: string) {
    this.selectedTab = tabName
    this.setUrlByTabName(tabName)
    this._cdr.detectChanges()
  }

  setUrlByTabName(tabName: string) {
    const urlComponents = this.router.url.split("/").filter((it) => !!it)

    const urlTree = this.router.createUrlTree(urlComponents.slice(0, urlComponents.length - 1).concat([tabName]))

    this.location.go(urlTree.toString())
  }
}

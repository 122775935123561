// @ts-nocheck
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Item } from "@models/common"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"
import { IBimsyncTokenData, NativebridgeTopic } from "@services/native/nativebridge.interface"
import { NativebridgeService } from "@services/native/nativebridge.service"
import { ScriptLoaderService } from "@services/script-loader.service"
import { TopicItemService } from "@services/topic-item.service"
import { OAuthService } from "angular-oauth2-oidc"
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs"
import { filter, switchMap, take, throttleTime } from "rxjs/operators"
import { BimsyncViewer2dComponent } from "../../components/bimsync-viewer2d/bimsync-viewer2d.component"
import { BimsyncViewer3dComponent } from "../../components/bimsync-viewer3d/bimsync-viewer3d.component"
import { I3DCoordinate, IBimsyncModelData, IBimsyncModelItemDataNative } from "../../models/bimsync.interface"
import { BimsyncViewService } from "../../services/bimsync-view.service"
import { BimsyncViewBaseClass } from "../bimsync-view-base-class"

@Component({
  selector: "checkd-bimsync-app-view",
  templateUrl: "./bimsync-app-view.component.html",
  styleUrls: ["../bimsync-view/bimsync-view.component.scss"],
})
export class BimsyncAppViewComponent extends BimsyncViewBaseClass implements OnInit, OnDestroy {
  scriptsLoaded = false
  subscriptions: Subscription[] = []

  @ViewChild("viewer2d", { static: false }) viewer2d: BimsyncViewer2dComponent
  @ViewChild("viewer3d", { static: false }) viewer3d: BimsyncViewer3dComponent

  selectedObjects: { objects: string[]; viewpoint: any }

  private selectedObjectsBoundingBox: { min: I3DCoordinate; max: I3DCoordinate }
  private selectedObjectsCentroid: I3DCoordinate

  public bimsyncTokenData$: Observable<IBimsyncTokenData> = this.nativebridgeService.bimsyncTokenData$.pipe(filter((it) => it != null))

  public currentProjectId$: Observable<string> = this.nativebridgeService.currentBimsyncProjectId$

  public currentModelId$: Observable<string> = this.nativebridgeService.currentBimsyncModelId$

  public currentProjectModels$: Observable<IBimsyncModelData[]> = combineLatest([this.currentModelId$]).pipe(
    switchMap(([id]) => this.bimsyncApi.v2_getProjectModels(id))
  )

  public items$ = new BehaviorSubject([])

  // public currentBimsyncModel$ =
  //   combineLatest([
  //     this.currentModelId$,
  //     this.integrationsData$,
  //   ]).pipe(map(([bimsyncModelId, data]) => {
  //     return data.models[bimsyncModelId] as IBimsyncModelData
  //   }))

  constructor(
    public bimsyncApi: BimsyncApiV2Service,
    private scriptLoader: ScriptLoaderService,
    private nativebridgeService: NativebridgeService,
    public oAuthService: OAuthService,
    public topicItemService: TopicItemService,
    private bimsyncViewService: BimsyncViewService
  ) {
    super(false, true, true)
  }

  async ngOnInit() {
    await this.loadScripts()

    this.nativebridgeService.addTopics(
      NativebridgeTopic.BIMSYNC_TOKEN_DATA,
      NativebridgeTopic.BIMSYNC_PROJECT_ID,
      NativebridgeTopic.BIMSYNC_MODEL_ID,
      NativebridgeTopic.BIMSYNC_ITEMS
    )
    this.setupSubscriptions()
    this.requestAuthCodeAndCredentials()
  }

  requestAuthCodeAndCredentials() {
    try {
      this.nativebridgeService.emitTopic(NativebridgeTopic.BIMSYNC_TOKEN_DATA, {})
      this.nativebridgeService.emitTopic(NativebridgeTopic.BIMSYNC_PROJECT_ID, {})
      this.nativebridgeService.emitTopic(NativebridgeTopic.BIMSYNC_MODEL_ID, {})
      this.nativebridgeService.emitTopic(NativebridgeTopic.BIMSYNC_ITEMS, {})
    } catch (err) {
      // Browser throws an error if there is no nativebridge detected.
      // Silent fail for now.
    }
  }

  setupSubscriptions() {
    this.subscriptions = [
      combineLatest([this.currentProjectId$, this.currentModelId$]).subscribe(([projectId, modelId]) => {
        this.bimsyncApi.currentBimsyncProject$.next({ id: projectId })
        this.bimsyncApi.currentBimsyncProjectModel$.next({ id: modelId })
        this.bimsyncViewService.currentModelId$.next(modelId)
      }),
      this.bimsyncTokenData$.subscribe((it) => {
        sessionStorage.setItem("access_token", it.accessToken)
        sessionStorage.setItem("refresh_token", it.refreshToken)
        this.oAuthService.tryLoginCodeFlow()
      }),
      this.nativebridgeService.bimsyncItems$.subscribe((it) => {
        const itemObjects = it.items.map((item: any) => new Item(item, item.uid, null))
        this.bimsyncViewService.currentItems$.next(itemObjects)
      }),
      this.bimsyncViewService.currentItemUid$.pipe(throttleTime(1000)).subscribe((itemUid) => {
        this.nativebridgeService.emitTopic(NativebridgeTopic.BIMSYNC_SHOW_ITEM, { value: itemUid })
      }),
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  async loadScripts() {
    try {
      await this.scriptLoader.load("jquery-2.1.1")
      await this.scriptLoader.load("bimsync-viewer")

      await this.scriptLoader.load("bimsync-viewer-2d")

      this.scriptsLoaded = true
    } catch (err) {
      console.error(err)
    }
  }

  onObjectsSelected(event: { objects: string[]; viewpoint: any }) {
    this.viewer3d.bimsyncViewer3d.viewer("boundingBox", event.objects, (boundingBox: any) => {
      this.selectedObjects = event
      this.selectedObjectsBoundingBox = boundingBox
      this.selectedObjectsCentroid = {
        x: (boundingBox.min.x + boundingBox.max.x) / 2,
        y: (boundingBox.min.y + boundingBox.max.y) / 2,
        z: (boundingBox.min.z + boundingBox.max.z) / 2,
      }
      this.viewer2d.select(event.objects)
    })
  }

  newItemClicked() {
    combineLatest([this.bimsyncApi.currentBimsyncProjectModel$, this.bimsyncApi.currentBimsyncProject$])
      .pipe(take(1))
      .subscribe(async ([model, project]) => {
        const screenshot3D = await this.viewer3d.getScreenshot()

        const modelData = {
          projectId: project.id,
          modelId: model.id,
          viewpoint: this.selectedObjects.viewpoint,
          objectId: this.selectedObjects.objects[0],
          position: {
            x: this.selectedObjectsCentroid.x || null,
            y: this.selectedObjectsCentroid.y || null,
            z: this.selectedObjectsCentroid.z || null,
          } as I3DCoordinate,
          snapshot3d: screenshot3D,
        } as IBimsyncModelItemDataNative

        this.nativebridgeService.emitTopic(NativebridgeTopic.BIMSYNC_CREATE_ITEM, modelData)
      })
  }
}

<div fxLayout="column" fxLayoutAlign="begin center">
  <p>Select the models you want to connect to this project.</p>
  <p>They will appear together with your drawings.</p>
  <mat-form-field>
    <mat-label>Models</mat-label>
    <mat-select [formControl]="models" multiple>
      <mat-option *ngFor="let model of data.models" [value]="model">{{ model.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="models.value != null && models.value.length > 0">
    <p>Selected models:</p>
    <ul>
      <li *ngFor="let model of models.value">{{ model.name }}</li>
    </ul>
  </div>

  <div class="model-selection__btn-container">
    <!-- <checkd-button bg="red" fg="white" (buttonClick)="dialogRef.close(null)">No</checkd-button>
    <checkd-button bg="green" fg="white" (buttonClick)="dialogRef.close(models.value)">Yes</checkd-button> -->
    <button primary-button [type]="'outline'" (click)="dialogRef.close(null)">No</button>
    <button primary-button (click)="dialogRef.close(models.value)">Yes</button>
  </div>
</div>

import { WorkflowStates } from "./workflow.interface"
import { zip } from "lodash-es"
import { ItemData } from "./item.interface"
import { IBimsyncModelItemData } from "../../features/bimsync/models/bimsync.interface"
import { ItemAggregateData } from "./aggregate-data"
import { BaseModel } from "./base-model"
import { COLLECTIONS } from "./collections.interface"
import { Drawing } from "./drawing"
import { Image } from "./image"
import { Person } from "./person"
import { Relation } from "./relation"
import { Task } from "./task"

export class Item extends BaseModel<ItemData> {
  public static override COLLECTION: string = "items"

  public task?: Task

  get name() {
    return this.data.name
  }

  get number() {
    // @ts-ignore
    return this.task != null ? this.task.number : this.data.number
  }
  set number(n: number) {
    this.data.number = n
  }

  get itemType() {
    return this.data.itemType
  }
  get description(): string {
    return this.data.description ?? ""
  }
  get positionX(): number {
    // @ts-ignore
    return this.data.positionX || null
  }

  set positionX(positionX: number) {
    this.data.positionX = positionX
  }

  get positionY(): number {
    // @ts-ignore
    return this.data.positionY || null
  }

  set positionY(positionY: number) {
    this.data.positionY = positionY
  }

  override get aggregateData(): ItemAggregateData {
    return this.data.aggregateData || {}
  }
  get bimsyncModelData(): Partial<IBimsyncModelItemData> {
    return this.data.bimsyncModelData || {}
  }

  get creatorName() {
    return this.aggregateData.itemCreatorName || ""
  }
  get creatorCompanyName() {
    return this.aggregateData.itemCreatorCompanyName || ""
  }
  get assignerName() {
    return this.aggregateData.taskAssignerName || ""
  }
  get assignerCompanyName() {
    return this.aggregateData.taskAssignerCompanyName || ""
  }
  get assigneeName() {
    return this.aggregateData.taskAssigneeName || ""
  }
  get assigneeCompanyName() {
    return this.aggregateData.taskAssigneeCompanyName || ""
  }
  get drawingName() {
    return this.aggregateData.drawingName || ""
  }
  get projectName() {
    return this.aggregateData.projectName || ""
  }

  // @ts-ignore
  override get createdAt() {
    return this.data.createdAt ? this.data.createdAt * 0.001 : null
  }

  // @ts-ignore
  override get updatedAt() {
    return this.data.updatedAt ? this.data.updatedAt * 0.001 : null
  }
  get dueDate(): number | null {
    return this.data.dueDate ? this.data.dueDate * 0.001 : null
  }

  get collaborators(): { uid: string; name: string }[] {
    // @ts-ignore
    return zip(this.aggregateData.taskCollaboratorUids || [], this.aggregateData.taskCollaboratorNames || []).map(([uid, name]) => ({
      uid,
      name,
    }))
  }

  get numberOfCollaborators(): number {
    return (this.collaborators || []).length
  }

  get status(): string {
    if (this.task != null && this.task.status) {
      return this.task.status
    }

    if (this.data.status == null) {
      return WorkflowStates.OPEN
    }

    return this.data.status
  }

  set status(status: string) {
    this.data.status = status
  }

  override get tags(): string[] {
    if (this.data.tags != null) {
      return this.data.tags
    }

    return []
  }

  get isDraggable(): boolean {
    const moveableStatuses = [WorkflowStates.OPEN, WorkflowStates.DELEGATED, WorkflowStates.REJECTED, WorkflowStates.CANCELLED]
    const status = this.status as WorkflowStates

    return moveableStatuses.includes(status)
  }

  getAssignee(): Promise<Person> {
    // @ts-ignore
    return (
      this.getTask()
        // @ts-ignore
        .then((task) => {
          if (task) {
            return task.getAssignee()
          }
        })
        .catch((error) => null)
    )
  }

  getAssigner(): Promise<Person> {
    // @ts-ignore
    return (
      this.getTask()
        // @ts-ignore
        .then((task) => {
          if (task) {
            return task.getAssigner()
          }
        })
        .catch((error) => null)
    )
  }

  getTask(): Promise<Task> {
    // @ts-ignore
    return Relation.getAllTargets(this, COLLECTIONS.TASKS).then((tasks) => (tasks != null && tasks.length > 0 ? (tasks[0] as Task) : null))
  }

  getCreator(): Promise<Person> {
    // @ts-ignore
    return (
      // @ts-ignore
      Relation.getAll(this, COLLECTIONS.PEOPLE)
        .then((relations) => relations.filter((relation) => relation.labels.includes(Relation.LABELS.CREATED_BY)))
        .then((filteredRels) => {
          return filteredRels.length > 0 ? filteredRels[0] : null
        })
        // @ts-ignore
        .then((personRel) => (personRel == null ? null : Person.get(personRel.uid)))
    )
  }

  getDrawing(): Promise<Drawing> {
    // @ts-ignore
    return Relation.getAllTargets(this, COLLECTIONS.DRAWINGS).then((drawings) =>
      drawings != null && drawings.length > 0 ? (drawings[0] as Drawing) : null
    )
  }

  getImages(): Promise<Image[]> {
    // @ts-ignore
    return Relation.getAllTargets(this, COLLECTIONS.IMAGES).then((targets) => targets as Image[])
  }

  // TODO Deprecate, use service methods instead
  public addToDrawing(drawing: Drawing): Promise<Item> {
    // @ts-ignore
    return Promise.all([drawing.add(this, [Relation.LABELS.CONTAINS]), this.add(drawing, [Relation.LABELS.CONTAINED_BY])]).then((_) => this)
  }

  public override update(data?: any) {
    const newData = { ...this.data, ...(data || {}) }

    return this.ref!.update(newData).then((_) => {
      this.data = newData

      return this.refresh()
    })
  }

  public static get(uid: string): Promise<Item> {
    // @ts-ignore
    return Item.getModel(Item, uid).then((item) => {
      return (
        item
          // @ts-ignore
          .getTask()
          .then((task: Task) => {
            if (task != null) {
              // @ts-ignore
              item.task = task
              // @ts-ignore
              item.status = task.status
            }
          })
          .then((_: any) => item)
      )
    })
  }

  public static create(data: ItemData): Promise<Item> {
    return Item.db
      .collection(Item.COLLECTION)
      .add(data)
      .then((docRef) => docRef.get())
      .then((docSnapshot) => new Item(docSnapshot.data() as ItemData, docSnapshot.id, docSnapshot.ref))
  }
}

// export class DrawingAnnotation extends Item {
//   public positionX: number
//   public positionY: number
// }

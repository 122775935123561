import { Injectable, NgZone } from "@angular/core"
// @ts-ignore
import * as nativebridge from "@nrk/nativebridge"
import { IBimsyncTokenData, INativebridgeMessage, NativebridgeTopic } from "@services/native/nativebridge.interface"
import { Observable, Subject } from "rxjs"
import { filter, map } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class NativebridgeService {
  constructor(private zone: NgZone) {}

  private _incoming$ = new Subject<INativebridgeMessage>()

  public bimsyncTokenData$: Observable<IBimsyncTokenData> = this.incoming$(NativebridgeTopic.BIMSYNC_TOKEN_DATA).pipe(
    map((message) => message.data)
  )

  public currentBimsyncProjectId$: Observable<string> = this.incoming$(NativebridgeTopic.BIMSYNC_PROJECT_ID).pipe(
    map((message) => message.data.projectId)
  )

  public currentBimsyncModelId$: Observable<string> = this.incoming$(NativebridgeTopic.BIMSYNC_MODEL_ID).pipe(
    map((message) => message.data.modelId)
  )

  public bimsyncItems$: Observable<any> = this.incoming$(NativebridgeTopic.BIMSYNC_ITEMS).pipe(map((message) => message.data))

  public incoming$(topic: NativebridgeTopic) {
    return this._incoming$.pipe(filter((message) => message.topic === topic))
  }

  addTopics(...topics: NativebridgeTopic[]) {
    for (const topic of topics) {
      nativebridge.on(topic, (data: any) => {
        this.zone.run(() => {
          this._incoming$.next({ topic, data })
        })
      })
    }
  }

  removeIncomingTopic(topic: NativebridgeTopic) {
    nativebridge.off(topic)
  }

  emitTopic(topic: NativebridgeTopic, data: any) {
    nativebridge.emit(topic, data)
  }
}

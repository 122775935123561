import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms"

@Component({
  selector: "checkd-bimsync-authentication",
  templateUrl: "./bimsync-authentication.component.html",
  styleUrls: ["./bimsync-authentication.component.scss"],
})
export class BimsyncAuthenticationComponent implements OnInit {
  @Input() authCode: string
  @Output() authenticate = new EventEmitter()
  authForm: UntypedFormGroup

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.authForm = this.formBuilder.group({
      client_id: "",
      client_secret: "",
    })
  }
}

/**
 * Lifted from https://medium.com/better-programming/angular-load-external-javascript-file-dynamically-3d14dde815cb
 */

import { Injectable } from "@angular/core"

interface Scripts {
  name: string
  src: string
}

export const ScriptStore: Scripts[] = [
  { name: "bimsync-viewer", src: "https://api.bimsync.com/1.0/js/viewer.js" },
  { name: "bimsync-viewer-2d", src: "https://api.bimsync.com/1.0/js/viewer2d.js" },
  { name: "jquery-2.1.1", src: "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.1/jquery.min.js" },
]

declare var document: any

@Injectable()
export class ScriptLoaderService {
  private scripts: any = {}

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      }
    })
  }

  load(...scripts: string[]) {
    const promises: any[] = []
    scripts.forEach((script) => promises.push(this.loadScript(script)))
    return Promise.all(promises)
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement("script")
        script.type = "text/javascript"
        script.src = this.scripts[name].src
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null
              this.scripts[name].loaded = true
              resolve({ script: name, loaded: true, status: "Loaded" })
            }
          }
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true
            resolve({ script: name, loaded: true, status: "Loaded" })
          }
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: "Loaded" })
        document.getElementsByTagName("head")[0].appendChild(script)
      } else {
        resolve({ script: name, loaded: true, status: "Already Loaded" })
      }
    })
  }
}

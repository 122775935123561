import { Component, Inject, OnInit } from "@angular/core"
import { UntypedFormControl } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

export interface IModelSelectionDialogData {
  models: any
}

@Component({
  selector: "checkd-model-selection-dialog",
  templateUrl: "./model-selection-dialog.component.html",
  styleUrls: ["./model-selection-dialog.component.scss"],
})
export class ModelSelectionDialogComponent {
  public models = new UntypedFormControl([])

  constructor(
    public dialogRef: MatDialogRef<ModelSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModelSelectionDialogData
  ) {}
}

<ng-template #needBimsyncAccess>
  <div>
    <p>Please go to your profile page to authorize access your BimSync account</p>
  </div>
</ng-template>

<div *ngIf="oAuthService.hasValidAccessToken() as authCode; else needBimsyncAccess" class="page-container" fxLayout="column">
  <img fxHide.lt-md class="checkd-logo" src="assets/checkd-logo-square.svg" alt="CHECKD logo"/>

  <mat-toolbar fxFlex="0 0 auto" class="top-toolbar" fxLayout="column" fxLayoutAlign="center center">
    <div class="toolbar-top-row" fxLayout="row" fxLayoutAlign="center center">
      <checkd-bimsync-model-menu
        fxHide.gt-sm
        Class="main-side-panel-button left"
        (visibilityChanged)="onVisibilityChanged($event)"
        [bimsyncModels]="currentAttachedBimsyncModels$ | async">
      </checkd-bimsync-model-menu>

      <span>{{ (currentBimsyncModel$ | async)?.name }}</span>
      <button mat-icon-button (click)="projectSidePanel.toggle()">
        <mat-icon fxHide.gt-sm >menu</mat-icon>
      </button>
    </div>

    <div fxLayout="row" fxHide.lt-md class="toolbar-bottom-row" fxLayoutAlign="space-evenly center">
      <span><b>Project</b> {{(projectService.currentProject$ | async)?.name}} </span>
    </div>
  </mat-toolbar>

  <mat-sidenav-container fxFlex="1 1 0">
<!--        <checkd-bimsync-model-list-->
<!--          selectionType="multi"-->
<!--          [bimsyncModels]="currentAttachedBimsyncModels$ | async"-->
<!--          (selectModel)="bimsyncApiV2.currentBimsyncProjectModel$.next($event)">-->
<!--        </checkd-bimsync-model-list>-->

<!--        <section fxLayout="column" fxLayoutAlign="begin">-->
<!--          <h4>Navigation settings</h4>-->
<!--          <mat-checkbox [(ngModel)]="bimNavigation.mouseRotationEnabled">Mouse rotation</mat-checkbox>-->
<!--          <mat-label>Movement sensitivity-->
<!--            <input matInput placeholder="Movement sensitivity" type="number" [(ngModel)]="bimNavigation.movementSensitivity">-->
<!--          </mat-label>-->

<!--          <mat-label>-->
<!--            Rotation Sensitivity-->
<!--            <input matInput placeholder="Rotation sensitivity" type="number" [(ngModel)]="bimNavigation.rotationSensitivity">-->
<!--          </mat-label>-->
<!--        </section>-->

    <mat-sidenav

      #projectSidePanel
      class="info-sidenav"
      position="end">
      <h2 (click)="projectSidePanel.toggle()">Items</h2>
      <mat-checkbox [(ngModel)]="shouldShowAllItemObjects" (change)="showAllItemObjects(shouldShowAllItemObjects)">Show related objects</mat-checkbox>
      <mat-divider></mat-divider>
      <mat-list>
        <mat-list-item *ngFor="let item of items$ | async"
                       (click)="openItem(item)"
                       style="cursor: pointer;"
                       class="item-list-element"
                       (mouseover)="showItemObject(item)"
                       (mouseout)="showAllObjects()">
          <div fxLayout="row" fxLayoutAlign="begin center">
            <checkd-status-icon [type]="item?.status" [number]="item?.number"></checkd-status-icon>
            <span>{{ item?.name }}</span>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-sidenav>

    <mat-sidenav-content>

      <checkd-bimsync-model-menu
        fxHide.lt-md
        class="main-side-panel-button left"
        (visibilityChanged)="onVisibilityChanged($event)"
        [bimsyncModels]="currentAttachedBimsyncModels$ | async">
      </checkd-bimsync-model-menu>

      <button
        type="button"
        fxHide.lt-md
        class="main-side-panel-button right"
        aria-label="Toggle project info side panel"
        mat-icon-button
        (click)="projectSidePanel.toggle()">
        <mat-icon aria-label="Toggle project info side panel icon">menu</mat-icon>
      </button>

      <section class="viewer-area">
        <as-split [direction]="viewerDirectionHorizontal ? 'horizontal' : 'vertical'" (dragEnd)="triggerRedraw()">
          <as-split-area [size]="50" [visible]="viewer3dVisible" [order]="1">
            <checkd-bimsync-viewer3d
              #viewer3d
              (objectsSelected)="onObjectsSelected($event)">
            </checkd-bimsync-viewer3d>
          </as-split-area>

          <as-split-area [size]="50" [visible]="viewer2dVisible" [order]="2">
            <checkd-bimsync-viewer2d
              fxFlexAlign="center"
              style="background-color: white;"
              #viewer2d>
            </checkd-bimsync-viewer2d>
          </as-split-area>
        </as-split>
      </section>


<!--      <div *ngIf="bimsyncApiV2.currentObjectInfo$ | async as currentObjectInfo"-->
<!--           cdkDrag-->
<!--           cdkDragBoundary="mat-sidenav-content"-->
<!--           class="bimsync-product-info">-->
<!--        <checkd-bimsync-object-tree [objectData]="bimsyncApiV2.currentObjectInfo$ | async"></checkd-bimsync-object-tree>-->
<!--      </div>-->

      <checkd-button
        *ngIf="selectedObjects && selectedObjects.objects.length > 0"
        (buttonClick)="newItemClicked()"
        bg="red" fg="white">
        (+) New item
      </checkd-button>

      <checkd-bimsync-action-bar
        cdkDrag
        cdkDragBoundary="mat-sidenav-content"
        class="drag-and-drop-container checkd-border mat-elevation-z2"
        [(viewer2dVisible)]="viewer2dVisible"
        [(viewer3dVisible)]="viewer3dVisible"
        [(viewerDirectionHorizontal)]="viewerDirectionHorizontal"
        fxLayout="column"
      ></checkd-bimsync-action-bar>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>


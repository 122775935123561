import { Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { OAuthProvider } from "@firebase/auth"
import { map } from "rxjs/operators"

@Injectable()
export class FirebaseAuthService {
  constructor(public afAuth: AngularFireAuth) {}

  login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
  }

  loginWithCustomToken(token: string) {
    return this.afAuth.signInWithCustomToken(token)
  }

  logout() {
    return this.afAuth.signOut()
  }

  verifyPasswordResetCode(code: string) {
    return this.afAuth.verifyPasswordResetCode(code)
  }

  checkActionCode(code: string) {
    return this.afAuth.checkActionCode(code)
  }

  applyActionCode(code: string) {
    return this.afAuth.applyActionCode(code)
  }

  resetPassword(code: string, newPassword: string) {
    return this.afAuth.confirmPasswordReset(code, newPassword)
  }

  get auth() {
    return this.afAuth
  }

  get authState$() {
    return this.afAuth.authState
  }

  isLoggedIn() {
    return this.afAuth.authState.pipe(map((user) => !!user))
  }

  public async linkAccount(providerId = "microsoft.com") {
    const provider = new OAuthProvider(providerId)
    const currentUser = await this.afAuth.currentUser

    return currentUser!.linkWithPopup(provider)
  }

  public async unlinkAccount(providerId = "microsoft.com") {
    const currentUser = await this.afAuth.currentUser

    return currentUser!.unlink(providerId)
  }
}

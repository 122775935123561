import { Component, EventEmitter, Input, Output } from "@angular/core"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"

@Component({
  selector: "checkd-bimsync-storey-list",
  templateUrl: "./bimsync-storey-list.component.html",
  styleUrls: ["./bimsync-storey-list.component.scss"],
})
export class BimsyncStoreyListComponent {
  @Input() bimsyncStoreys: any[]
  @Output() selectStorey = new EventEmitter<any>()

  constructor(public bimsyncApiService: BimsyncApiV2Service) {
    return
  }
}

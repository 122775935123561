import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { SnackbarService } from "@services"
import { OAuthService } from "angular-oauth2-oidc"
import { BehaviorSubject, combineLatest, EMPTY, Observable, ReplaySubject, Subject } from "rxjs"
import { catchError, debounceTime, map, share, switchMap, take } from "rxjs/operators"
import {
  IBimsyncModelData,
  IBimsyncIFCProduct,
  IBimsyncProjectData,
  IBimsyncViewerTokenResult,
} from "../../features/bimsync/models/bimsync.interface"

@Injectable({
  providedIn: "root",
})
export class BimsyncApiV2Service {
  apiBaseUrl = "https://api.bimsync.com/v2"

  public currentBimsyncProject$ = new ReplaySubject<Partial<IBimsyncProjectData>>()
  public currentBimsyncProjectModel$ = new ReplaySubject<Partial<IBimsyncModelData>>()
  public currentBimsyncProjectModelStoreys$ = new Subject()
  public currentSelectedBimsyncProjectModels$ = new Subject<IBimsyncModelData[]>()
  public currentSelectedStorey$ = new Subject<any>()
  public currentSelectedBimsyncObjectIds$ = new BehaviorSubject<string[]>([])

  public bimsyncProjects$: Observable<IBimsyncProjectData[]> = this.v2_getProjects().pipe(share())

  public bimsyncProjectModels$: Observable<{ [projectId: string]: IBimsyncModelData[] }> = combineLatest([this.bimsyncProjects$]).pipe(
    switchMap(([projects]) => {
      return combineLatest(projects.map((project) => this.v2_getProjectModels(project.id))).pipe(
        map((projectModels) => Object.assign({}, ...projectModels.map((models, i) => ({ [projects[i].id]: models }))))
      )
    })
  )

  public currentBimsyncProjectModels$: Observable<IBimsyncModelData[]> = combineLatest([
    this.currentBimsyncProject$,
    this.bimsyncProjectModels$,
  ]).pipe(
    map(([currentProject, projectModels]) =>
      Object.keys(projectModels).includes(currentProject.id!) ? projectModels[currentProject.id!] : []
    )
  )

  public currentViewer3dUrl$: Observable<string> = combineLatest([this.currentBimsyncProject$]).pipe(
    switchMap(([project]) => this.v2_getViewerAccessUrl(project.id!))
  )

  public currentViewer2dUrl$: Observable<string> = combineLatest([this.currentBimsyncProject$, this.currentBimsyncProjectModel$]).pipe(
    switchMap(([project, model]) => this.v2_get2dViewerAccessUrl(project.id!, model.id!))
  )

  public currentObjectInfo$: Observable<IBimsyncIFCProduct> = combineLatest([
    this.currentBimsyncProject$,
    this.currentSelectedBimsyncObjectIds$,
  ]).pipe(
    switchMap(([project, objectIds]) => {
      if (project.id == null || objectIds.length < 1) {
        return EMPTY
      }

      return this.v2_getObjectInfo(project.id, objectIds[0])
    })
  )

  constructor(private httpClient: HttpClient, private snackBar: SnackbarService, private oAuthService: OAuthService) {}

  public v2_getObjectInfo(bimsyncProjectId: string, bimsyncObjectId: string): Observable<IBimsyncIFCProduct> {
    const url = `${this.apiBaseUrl}/projects/${bimsyncProjectId}/ifc/products/${bimsyncObjectId}`
    const accessToken = this.oAuthService.getAccessToken()
    const headers = new HttpHeaders().set("Authorization", `Bearer ${accessToken}`)

    return this.httpClient.get<IBimsyncIFCProduct>(url, { headers }).pipe(
      catchError((err, caught) => {
        // console.error('Error getting object info', err)
        // this.snackBar.showMessage(err.message)

        return EMPTY
      })
    )
  }

  public v2_getProjects(): Observable<IBimsyncProjectData[]> {
    return this.httpClient.get<IBimsyncProjectData[]>(`${this.apiBaseUrl}/projects`).pipe(
      catchError((err, caught) => {
        // console.error('Error getting access token', err)
        // this.snackBar.showMessage(err.message)

        return EMPTY
      })
    )
  }

  public v2_getProjectModels(projectId: string): Observable<IBimsyncModelData[]> {
    return this.httpClient.get<IBimsyncModelData[]>(`${this.apiBaseUrl}/projects/${projectId}/models`).pipe(
      map((models) => models.map((model) => ({ ...model, bimsyncProjectId: projectId }))),
      catchError((err, caught) => {
        // console.error('Error getting access token', err)
        // this.snackBar.showMessage(err.message)

        return EMPTY
      })
    )
  }

  public v2_getViewerAccessUrl(projectId: string) {
    if (projectId == null || projectId.trim() === "") {
      return EMPTY
    }

    const accessToken = this.oAuthService.getAccessToken()

    const headers = new HttpHeaders().set("Authorization", `Bearer ${accessToken}`).set("Content-Type", "application/json")

    return this.httpClient
      .post<IBimsyncViewerTokenResult>(`${this.apiBaseUrl}/projects/${projectId}/viewer3d/token`, null, { headers })
      .pipe(
        catchError((err, caught) => {
          // console.error('Error getting access token', err)
          // this.snackBar.showMessage(err.message)

          return EMPTY
        }),
        map((result) => result.url)
      )
  }

  public v2_get2dViewerAccessUrl(projectId: string, modelId: string) {
    if (projectId == null || projectId.trim() === "" || modelId == null || modelId.trim() === "") {
      return EMPTY
    }

    const accessToken = this.oAuthService.getAccessToken()

    const headers = new HttpHeaders().set("Content-Type", "application/json").set("Authorization", `Bearer ${accessToken}`)

    return this.httpClient
      .post<IBimsyncViewerTokenResult>(`${this.apiBaseUrl}/projects/${projectId}/viewer2d/token`, null, { headers })
      .pipe(
        catchError((err, caught) => {
          // console.error('Error getting access token', err)
          // this.snackBar.showMessage(err.message)

          return EMPTY
        }),
        map((result) => result.url)
      )
  }

  // public v2_createProject(name: string): Observable<IBimsyncNewProjectResult> {
  //   const body = { name }
  //
  //   return this.httpClient.post<IBimsyncNewProjectResult>(
  //     'https://api.bimsync.com/1.0/projects/new',
  //     body,
  //   ).pipe(
  //     catchError((err, _) => {
  //       console.error('Error creating new project: ', err)
  //       this.snackBar.showMessage(err.error.message)
  //
  //       return EMPTY
  //     }),
  //   )
  // }
  //
  // v1_deleteProject(projectId: string) {
  //   return this.bimsyncAccessToken$
  //     .pipe(
  //       switchMap(accessToken => {
  //         if (accessToken == null || accessToken.trim() === '' || projectId == null || projectId.trim() === '') {
  //           return EMPTY
  //         }
  //
  //         const httpOptions = {
  //           headers: new HttpHeaders({
  //                                      'content-type': 'application/x-www-form-urlencoded',
  //                                      Authorization: `Bearer ${accessToken}`,
  //                                    }),
  //         }
  //
  //         return this.httpClient.delete(
  //           `https://api.bimsync.com/1.0/project?project_id=${projectId}`,
  //           httpOptions,
  //         ).pipe(
  //           catchError((err, _) => {
  //             console.error('Error deleting project with project ID: ', projectId)
  //             this.snackBar.showMessage(err.error.message)
  //
  //             return EMPTY
  //           }),
  //         )
  //       }),
  //       take(1),
  //     )
  // }
}

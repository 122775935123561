<ng-container *ngIf="authCode">
  <h2>Authentication</h2>

  <form [formGroup]="authForm" (ngSubmit)="authenticate.emit(authForm.value)">
    <div>
      <label for="client_id">
        Client ID
      </label>
      <input id="client_id" type="text" formControlName="client_id">
    </div>

    <div>
      <label for="client_secret">
        Client secret
      </label>
      <input id="client_secret" type="password" formControlName="client_secret">
    </div>

    <button class="button" type="submit">Authenticate</button>

  </form>

</ng-container>


<div class="drawing-list-container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center">

  <div fxLayout="row wrap" fxLayoutAlign="center center">

    <div *ngFor="let model of models"
         (click)="modelSelected.emit(model)"
         class="drawing-list-item"
         fxLayout="row"
         fxLayoutAlign="space-between end">

      <div class="drawing-list-item-image">
        .ifc
      </div>

      <div class="drawing-list-item-info">
        <div class="left-info" fxLayout="column" fxLayoutAlign="begin end">
          <span class="drawing-name">{{model.name}}</span>
        </div>
      </div>

    </div>
  </div>
</div>

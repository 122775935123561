import { DragDropModule } from "@angular/cdk/drag-drop"
import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FlexLayoutModule } from "@angular/flex-layout"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CheckdCommonModule, CheckdMaterialModule } from "@checkd-ui"
import { ProjectService } from "@services"
import { AvatarModule } from "ngx-avatars"
import { ToastModule } from "primeng/toast"
import { ItemsModule } from "../items/items.module"
import { ItemDuplicationDialogComponent } from "./dialogs/item-duplication-dialog/item-duplication-dialog.component"
import { NewProjectViewComponent } from "./new-project-view/new-project-view.component"
import { ProjectContextmenuComponent } from "./project-contextmenu/project-contextmenu.component"
import { ProjectCreationComponent } from "./project-creation/project-creation.component"
import { ProjectItemsComponent } from "./project-items/project-items.component"
import { ProjectMemberRemovalDialogComponent } from "./project-member-removal-dialog/project-member-removal-dialog.component"
import { ProjectTabbarComponent } from "./project-tabbar/project-tabbar.component"
import { ProjectTemplateListComponent } from "./project-template-list/project-template-list.component"
import { BimsyncModule } from "../features/bimsync/bimsync.module"
import { ProjectModelListComponent } from "./project-model-list/project-model-list.component"
import { ProjectItemsTopActionBarComponent } from "./project-view/project-items-top-action-bar/project-items-top-action-bar.component"
import { ProjectDuplicationDialogComponent } from "./project-duplication-dialog/project-duplication-dialog.component"
import { ProjectFormsNotificationsRulesComponent } from "./project-forms-notifications-rules/project-forms-notifications-rules.component"
import { CsvExportDialogComponent } from "./dialogs/csv-export-dialog/csv-export-dialog.component"
import { ButtonModule } from "../next-ui/button/button.module"

// TODO Add all components etc. for projects and use the module in app.module

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CheckdCommonModule,
    CheckdMaterialModule,
    FlexLayoutModule,
    ItemsModule,
    AvatarModule,
    BimsyncModule,
    ToastModule,
    DragDropModule,
    ButtonModule,
  ],
  declarations: [
    ProjectCreationComponent,
    NewProjectViewComponent,
    ProjectContextmenuComponent,
    ItemDuplicationDialogComponent,
    ProjectTabbarComponent,
    ProjectTemplateListComponent,
    ProjectItemsComponent,
    ProjectMemberRemovalDialogComponent,
    ProjectItemsTopActionBarComponent,
    ProjectModelListComponent,
    ProjectDuplicationDialogComponent,
    CsvExportDialogComponent,
    ProjectFormsNotificationsRulesComponent,
  ],
  providers: [ProjectService],
  exports: [
    ProjectCreationComponent,
    NewProjectViewComponent,
    ProjectContextmenuComponent,
    ProjectTabbarComponent,
    ProjectTemplateListComponent,
    ProjectItemsComponent,
    ProjectModelListComponent,
    ProjectFormsNotificationsRulesComponent,
    ButtonModule,
  ],
})
export class ProjectModule {}

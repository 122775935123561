import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { FormViewComponent } from "@checkd-form/form-view/form-view.component"
import { CompanyViewComponent } from "@company"
import { DrawingViewComponent } from "@drawings"
import { MyItemsComponent } from "@items"
import { ProfileViewComponent } from "@people"
import { authGuard } from "./dashboard/guards/auth.guard"
import { InsightsGuard } from "./dashboard/guards/insights.guard"
import { LoginViewComponent } from "./dashboard/login-view/login-view.component"
import { AccountActivationViewComponent } from "./dashboard/login/account-activation-view/account-activation-view.component"
import { PasswordRecoveryViewComponent } from "./dashboard/login/password-recovery-view/password-recovery-view.component"
import { PasswordResetComponent } from "./dashboard/login/password-reset/password-reset.component"
import { NotFoundComponent } from "./dashboard/not-found/not-found.component"
import { FormsOverviewComponent } from "./dashboard/pages/forms-overview/forms-overview.component"
import { NewProjectViewComponent, ProjectsViewComponent, ProjectViewComponent } from "./dashboard/project"
import { FormsV2Guard } from "./forms-v2-guard.service"
import { EmailVerificationViewComponent } from "./dashboard/login/email-verification-view/email-verification-view.component"
import { AuthActionComponent } from "./dashboard/login/auth-action/auth-action.component"

const routes: Routes = [
  { path: "", redirectTo: "/projects", pathMatch: "full" },
  { path: "login", component: LoginViewComponent },
  { path: "passwordrecovery", component: PasswordRecoveryViewComponent },
  { path: "useractivation", component: AccountActivationViewComponent },
  { path: "passwordreset", component: PasswordResetComponent },
  { path: "emailverification", component: EmailVerificationViewComponent },
  { path: "authaction", component: AuthActionComponent },
  { path: "profile", component: ProfileViewComponent },
  { path: "items", component: MyItemsComponent, canActivate: [authGuard] },
  { path: "company", component: CompanyViewComponent, canActivate: [authGuard] },

  {
    path: "qr",
    loadChildren: () => import("./dashboard/features/qr-views/qr-views.module").then((m) => m.QrViewsModule),
  },

  // REPORT ROUTES
  {
    path: "",
    loadChildren: () => import("./dashboard/reports/reports.module").then((m) => m.ReportsModule),
  },

  // TODO Check if this is actually in use:
  { path: "projects/:projectId/reports/:reportId", component: FormViewComponent, canActivate: [authGuard] },

  // PROJECT ROUTES
  { path: "projects/new", component: NewProjectViewComponent, canActivate: [authGuard] },
  { path: "projects", component: ProjectsViewComponent, canActivate: [authGuard] },
  { path: "projects/:projectId", redirectTo: "/projects/:projectId/items", pathMatch: "full" },
  { path: "projects/:projectId/:tabName", component: ProjectViewComponent, canActivate: [authGuard] },
  { path: "projects/:projectId/drawings/:drawingId", component: DrawingViewComponent, canActivate: [authGuard] },

  // INVITATION ROUTES
  {
    path: "invitations",
    loadChildren: () => import("./dashboard/invitations/invitation.module").then((m) => m.InvitationModule),
  },

  // FORMSv2 ROUTES
  {
    path: "formsv2",
    loadChildren: () => import("../../projects/checkd-forms/src/app/feature.module").then((m) => m.AppModule),
    canActivate: [FormsV2Guard],
  },

  // FORMS ROUTES
  {
    path: "templateCreator",
    loadChildren: () => import("./dashboard/template-creator/template-creator.module").then((m) => m.TemplateCreatorModule),
    canActivate: [authGuard],
  },
  {
    path: "formsBuilder",
    loadChildren: () => import("./dashboard/template-creator/template-creator.module").then((m) => m.TemplateCreatorModule),
    canActivate: [authGuard],
  },

  {
    path: "forms",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./dashboard/features/public-template-library/public-template-library.module").then((m) => m.PublicTemplateLibraryModule),
      },
      {
        path: "builder",
        loadChildren: () => import("./dashboard/template-creator/template-creator.module").then((m) => m.TemplateCreatorModule),
        canActivate: [authGuard],
      },
      {
        path: "company-templates",
        component: NotFoundComponent, // TODO replace with actual component
      },
    ],
  },
  {
    path: "chat",
    loadChildren: () => import("./dashboard/features/chat/chat.module").then((m) => m.ChatModule),
  },

  // INSIGHTS ROUTES
  {
    path: "insights",
    loadChildren: () => import("./dashboard/features/insights/insights.module").then((m) => m.InsightsModule),
    canActivate: [InsightsGuard],
  },

  { path: "404", component: NotFoundComponent },
  { path: "**", redirectTo: "/404" },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

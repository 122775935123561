<ng-template #needAuthCode>
  <div>
    <p>Please go to your profile page to authorize access your BimSync account</p>
  </div>
</ng-template>

<div *ngIf="oAuthService.hasValidAccessToken(); else needAuthCode" class="page-container mobile-container" fxLayout="column">
  <mat-sidenav-container fxFlex="1 1 0">

    <mat-sidenav
      #projectSidePanel
      class="info-sidenav" position="end">
      project info thingy
    </mat-sidenav>

    <mat-sidenav-content>
      <checkd-bimsync-model-menu
        class="main-side-panel-button left">
      </checkd-bimsync-model-menu>

      <button
        type="button"
        class="main-side-panel-button right"
        aria-label="Toggle project info side panel"
        mat-icon-button
        (click)="projectSidePanel.toggle()">
        <mat-icon aria-label="Toggle project info side panel icon">menu</mat-icon>
      </button>

      <section class="viewer-area">
        <as-split [direction]="viewerDirectionHorizontal ? 'horizontal' : 'vertical'" (dragEnd)="triggerRedraw()">
          <as-split-area [size]="50" [visible]="viewer3dVisible" [order]="1">
            <checkd-bimsync-viewer3d
              #viewer3d
              (objectsSelected)="onObjectsSelected($event)">
            </checkd-bimsync-viewer3d>
          </as-split-area>

          <as-split-area [size]="50" [visible]="viewer2dVisible" [order]="2">
            <checkd-bimsync-viewer2d
              fxFlexAlign="center"
              style="background-color: white;"
              #viewer2d>
            </checkd-bimsync-viewer2d>
          </as-split-area>
        </as-split>
      </section>


      <checkd-button
        *ngIf="selectedObjects && selectedObjects.objects.length > 0"
        (buttonClick)="newItemClicked()"
        bg="red" fg="white">
        (+) New item
      </checkd-button>

      <checkd-bimsync-action-bar
        cdkDrag
        cdkDragBoundary="mat-sidenav-content"
        class="drag-and-drop-container checkd-border mat-elevation-z2"
        fxLayout="column"
        [(viewer2dVisible)]="viewer2dVisible"
        [(viewer3dVisible)]="viewer3dVisible"
        [(viewerDirectionHorizontal)]="viewerDirectionHorizontal"
      ></checkd-bimsync-action-bar>

    </mat-sidenav-content>
  </mat-sidenav-container>
</div>



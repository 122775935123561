import { Injectable } from "@angular/core"
import { idTokenResult } from "@angular/fire/compat/auth-guard"
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router"
import { FirebaseAuthService } from "@services"
import firebase from "firebase/compat/app"
import { from, Observable } from "rxjs"
import { map, pluck, switchMap } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class FormsV2Guard {
  constructor(public auth: FirebaseAuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Makes sure the user's custom claim is of admin level
    return this.auth.authState$.pipe(
      switchMap((currentUser) => from(currentUser!.getIdTokenResult())),
      pluck("claims"),
      map((claims) => !!claims["admin"])
    )
  }
}

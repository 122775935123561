<div class="content-wrapper" fxLayout="column" fxLayoutAlign="start stretch">
  <h3>Storeys</h3>

  <ng-container *ngIf="bimsyncApiService.currentSelectedStorey$ | async as currentStorey; else loading">
    <div>
      <mat-list fxFlexFill>
        <mat-list-item *ngFor="let storey of bimsyncStoreys; let last = last" matRipple (click)="selectStorey.emit(storey)">
          <mat-divider></mat-divider>
          <mat-icon *ngIf="storey.id == currentStorey.id" matListIcon>visibility</mat-icon>
          <p matLine [class.extra-padding]="storey.id !== currentStorey.id">{{storey?.name}}</p>
        </mat-list-item>
      </mat-list>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="loading-container" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>

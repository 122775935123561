import { Component, OnInit } from "@angular/core"

@Component({
  selector: "checkd-forms-overview",
  templateUrl: "./forms-overview.component.html",
  styleUrls: ["./forms-overview.component.scss"],
})
export class FormsOverviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

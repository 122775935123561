import { Component, OnInit } from "@angular/core"
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore"
import { map, shareReplay } from "rxjs/operators"
import { Observable } from "rxjs"

@Component({
  selector: "checkd-advertisement",
  templateUrl: "./advertisement.component.html",
  styleUrls: ["./advertisement.component.scss"],
})
export class AdvertisementComponent implements OnInit {
  htmlString: Observable<string>
  active: Observable<boolean>

  constructor(private db: AngularFirestore) {
    const adsConfig$ = this.db
      .collection("marketing")
      .doc("adsConfig")
      .snapshotChanges()
      .pipe(
        map((snap) => snap.payload.data() as any),
        shareReplay({ bufferSize: 1, refCount: true })
      )

    this.htmlString = adsConfig$.pipe(map((data) => (data.html ? data.html : "")))
    this.active = adsConfig$.pipe(map((data) => (data.active ? data.active : false)))
  }

  ngOnInit() {}
}

import { Component, EventEmitter, Input, Output } from "@angular/core"
import { IBimsyncModelData } from "../../models/bimsync.interface"

@Component({
  selector: "checkd-bimsync-model-menu",
  templateUrl: "./bimsync-model-menu.component.html",
  styleUrls: ["./bimsync-model-menu.component.scss"],
})
export class BimsyncModelMenuComponent {
  // @ts-ignore
  @Input() bimsyncModels: IBimsyncModelData[] = null
  @Output() visibilityChanged = new EventEmitter<IBimsyncModelData[]>()

  onItemClicked(i: number) {
    this.bimsyncModels[i].isVisible = !this.bimsyncModels[i].isVisible

    this.visibilityChanged.emit(this.bimsyncModels)
  }
}

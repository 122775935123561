<div class="profile" fxLayout="column" fxLayoutAlign="center center" *ngIf="userService.currentUser$ | async as currentUser">
  <profile-personal (onSave)="savePersonal($event)" [person]="currentUser"></profile-personal>
  <profile-image (uploadProfileImage)="uploadProfileImage()" [person]="currentUser"></profile-image>
  <profile-settings [person]="currentUser"></profile-settings>

  <ng-container *ngIf="userService.providerData$ | async as providerData">
    <div fxLayout="column" fxLayoutAlign="begin center">
      <h2>Login methods</h2>
      <div
        *ngIf="(userService.currentCompanyFeatures$ | async)?.includes(CompanyFeatures.SSO_AD_LOGIN)"
        (click)="toggleMicrosoftSSO(currentUser, providerData)"
        class="integration-button flex flex-row justify-content-start align-content-center checkd-white-bg"
      >
        <img src="/assets/icons/microsoft.svg" />
        <button class="checkd-blue-bg checkd-white cursor-pointer">
          {{ (hasMicrosoftLogin$ | async) ? "Deactivate" : "Activate" }} Microsoft login
        </button>
      </div>
    </div>

    <form
      id="default-email-form"
      *ngIf="defaultEmailForm"
      [formGroup]="defaultEmailForm"
      (ngSubmit)="updateDefaultEmailButtonPressed(currentUser)"
    >
      <h4 class="text-center mb-2">Update your default email</h4>
      <mat-radio-group class="flex flex-column" formControlName="selectedEmail">
        <mat-radio-button *ngFor="let provider of providerData" [value]="provider.email">{{ provider.email }}</mat-radio-button>
      </mat-radio-group>
    </form>

    <button
      *ngIf="defaultEmailForm"
      form="default-email-form"
      mat-button
      class="checkd-btn save-btn-color mt-3"
      type="submit"
      [disabled]="defaultEmailForm.pristine"
    >
      Update default email
    </button>
  </ng-container>
</div>

import { NestedTreeControl } from "@angular/cdk/tree"
import { Component, Input } from "@angular/core"
import { MatTreeNestedDataSource } from "@angular/material/tree"
import { BimsyncIFCCombinedData, IBimsyncIFCProduct } from "../../models/bimsync.interface"
import { BimsyncViewService, IBimsyncProductTreeData } from "../../services/bimsync-view.service"

@Component({
  selector: "checkd-bimsync-object-tree",
  templateUrl: "./bimsync-object-tree.component.html",
  styleUrls: ["./bimsync-object-tree.component.scss"],
})
export class BimsyncObjectTreeComponent {
  @Input() set objectData(data: IBimsyncIFCProduct) {
    this.treeDataSource.data = this.bimsyncViewService.convertBimsyncProductDataToTreeData(data as BimsyncIFCCombinedData)
  }

  public treeControl = new NestedTreeControl<IBimsyncProductTreeData>((node) => node.children)
  public treeDataSource = new MatTreeNestedDataSource<IBimsyncProductTreeData>()
  public hasChild = (_: number, node: IBimsyncProductTreeData) => !!node.children && node.children.length > 0

  constructor(private bimsyncViewService: BimsyncViewService) {}
}

// @ts-nocheck
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { ActivatedRoute } from "@angular/router"
import { COLLECTIONS, Item } from "@models/common"
import { IProjectBimsyncIntegrationData } from "@models/common/integrations/integrations.interface"
import { ItemService, ModelService, ProjectService, SnackbarService } from "@services"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"
import { BimNavigationService } from "@services/bim-navigation.service"
import { IntegrationsService } from "@services/integrations.service"
import { ScriptLoaderService } from "@services/script-loader.service"
import { UserService } from "@services/user.service"
import { OAuthService } from "angular-oauth2-oidc"
import { combineLatest, Observable, Subscription } from "rxjs"
import { filter, map, shareReplay, switchMap, take, throttleTime } from "rxjs/operators"
import { ItemDialogService } from "../../../../items/item-dialog.service"
import { BimsyncViewer2dComponent } from "../../components/bimsync-viewer2d/bimsync-viewer2d.component"
import { BimsyncViewer3dComponent } from "../../components/bimsync-viewer3d/bimsync-viewer3d.component"
import { I3DCoordinate, IBimsyncModelData, IBimsyncModelItemData } from "../../models/bimsync.interface"
import { BimsyncViewService, IBimsyncProductTreeData } from "../../services/bimsync-view.service"
import { BimsyncViewBaseClass } from "../bimsync-view-base-class"

@Component({
  selector: "checkd-bimsync-view",
  templateUrl: "./bimsync-view.component.html",
  styleUrls: ["./bimsync-view.component.scss"],
})
export class BimsyncViewComponent extends BimsyncViewBaseClass implements OnInit, OnDestroy {
  scriptsLoaded = false
  subscriptions: Subscription[] = []
  isDialogOpen = false

  selectedObjects: { objects: string[]; viewpoint: any }

  itemsMap: { [itemUid: string]: Item } = {}

  @ViewChild("viewer2d", { static: false }) viewer2d: BimsyncViewer2dComponent
  @ViewChild("viewer3d", { static: false }) viewer3d: BimsyncViewer3dComponent

  public currentProjectId$: Observable<string> = this.route.paramMap.pipe(
    filter((it) => it.has("bimsyncProjectId")),
    map((it) => it.get("bimsyncProjectId"))
  )

  public currentModelId$: Observable<string> = this.route.paramMap.pipe(
    filter((it) => it.has("bimsyncModelId")),
    map((it) => it.get("bimsyncModelId"))
  )

  public currentCheckdProjectId: Observable<string> = this.route.paramMap.pipe(
    filter((it) => it.has("projectId")),
    map((it) => it.get("projectId"))
  )

  public currentProjectModels$: Observable<IBimsyncModelData[]> = this.currentModelId$.pipe(
    switchMap((id) => this.bimsyncApiV2.v2_getProjectModels(id))
  )

  public items$: Observable<Item[]> = this.currentProjectId$.pipe(
    switchMap((projectId) => {
      return this.modelService.queryAndListen({
        collection: COLLECTIONS.ITEMS,
        modelData: { "bimsyncModelData.projectId": projectId },
        orderBy: { createdAt: "desc" },
      })
    })
  )

  public integrationsData$: Observable<IProjectBimsyncIntegrationData> = this.projectService.currentProject$.pipe(
    switchMap((project) => this.integrations.listenToProjectBimsyncIntegration(project))
  )

  public currentBimsyncIntegration$: Observable<IProjectBimsyncIntegrationData> = this.projectService.currentProject$.pipe(
    switchMap((project) => this.integrations.listenToProjectBimsyncIntegration(project))
  )

  public currentAttachedBimsyncModels$: Observable<IBimsyncModelData[]> = combineLatest([
    this.currentModelId$,
    this.currentBimsyncIntegration$.pipe(
      map((it) => Object.values(it.models || [])),
      shareReplay({ bufferSize: 1, refCount: true })
    ),
  ]).pipe(
    map(([currentModelId, models]) => {
      return models.map((m) => {
        if (m.id === currentModelId) {
          m.isVisible = true
        }

        return m
      })
    })
  )

  public currentBimsyncModel$ = combineLatest([this.currentModelId$, this.integrationsData$]).pipe(
    map(([bimsyncModelId, data]) => {
      // @ts-ignore
      return data.models[bimsyncModelId] as IBimsyncModelData
    })
  )

  // public treeControl = new NestedTreeControl<IBimsyncProductTreeData>(node => node.children)
  // public treeDataSource = new MatTreeNestedDataSource<IBimsyncProductTreeData>()
  public hasChild = (_: number, node: IBimsyncProductTreeData) => !!node.children && node.children.length > 0

  // public currentObjectTreeData$ =
  //   this.bimsyncApiV2.currentObjectInfo$.pipe(
  //     map(it => this.bimsyncViewService.convertBimsyncProductDataToTreeData(it as BimsyncIFCCombinedData)))

  private selectedObjectsBoundingBox: { min: I3DCoordinate; max: I3DCoordinate }
  private selectedObjectsCentroid: I3DCoordinate

  public shouldShowAllItemObjects = false

  constructor(
    private userService: UserService,
    private scriptLoader: ScriptLoaderService,
    public bimNavigation: BimNavigationService,
    public bimsyncApiV2: BimsyncApiV2Service,
    public oAuthService: OAuthService,
    private integrations: IntegrationsService,
    private route: ActivatedRoute,
    private itemDialogService: ItemDialogService,
    public projectService: ProjectService,
    private itemService: ItemService,
    private snackbarService: SnackbarService,
    private modelService: ModelService,
    public bimsyncViewService: BimsyncViewService,
    private dialog: MatDialog
  ) {
    super(true, true, true)
  }

  async ngOnInit() {
    await this.loadScripts()
    this.setupSubscriptions()
  }

  @HostListener("window:keydown", ["$event"])
  keyDownEvent(event: KeyboardEvent) {
    if (!this.isDialogOpen) {
      this.bimNavigation.keyDownEvent(event)
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyUpEvent(event: KeyboardEvent) {
    if (!this.isDialogOpen) {
      this.bimNavigation.keyUpEvent(event)
    }
  }

  @HostListener("mousemove", ["$event"])
  mouseEvent(event: MouseEvent) {
    this.bimNavigation.mouseEvent(event)
  }

  setupSubscriptions() {
    this.subscriptions = [
      combineLatest([this.currentProjectId$, this.currentModelId$]).subscribe(([projectId, modelId]) => {
        this.bimsyncApiV2.currentBimsyncProject$.next({ id: projectId })
        this.bimsyncApiV2.currentBimsyncProjectModel$.next({ id: modelId })
      }),

      this.currentCheckdProjectId.subscribe((id) => {
        this.projectService.setCurrentProject(id)
      }),

      this.items$.subscribe((items) => this.bimsyncViewService.currentItems$.next(items)),
      this.currentModelId$.subscribe((id) => this.bimsyncViewService.currentModelId$.next(id)),
      this.items$.subscribe((items) => {
        this.itemsMap = {}
        for (const item of items) {
          this.itemsMap[item.uid] = item
        }
      }),

      this.bimsyncViewService.currentItemUid$.pipe(throttleTime(1000)).subscribe((itemUid) => {
        if (this.itemsMap[itemUid] != null) {
          this.openItem(this.itemsMap[itemUid])
        }
      }),

      // this.currentObjectTreeData$.subscribe(it => { console.log(it); this.treeDataSource.data = it }),
      // this.bimsyncApiV2.currentObjectInfo$.subscribe(it => { console.log(it) }),

      this.dialog.afterAllClosed.subscribe(() => (this.isDialogOpen = false)),
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  async loadScripts() {
    try {
      await this.scriptLoader.load("jquery-2.1.1")
      await this.scriptLoader.load("bimsync-viewer")

      await this.scriptLoader.load("bimsync-viewer-2d")

      this.scriptsLoaded = true
    } catch (err) {
      console.error(err)
    }
  }

  onObjectsSelected(event: { objects: string[]; viewpoint: any }) {
    this.viewer3d.bimsyncViewer3d.viewer("boundingBox", event.objects, (boundingBox: { min: any; max: any }) => {
      this.selectedObjects = event
      this.selectedObjectsBoundingBox = boundingBox
      this.selectedObjectsCentroid = {
        x: (boundingBox.min.x + boundingBox.max.x) / 2,
        y: (boundingBox.min.y + boundingBox.max.y) / 2,
        z: (boundingBox.min.z + boundingBox.max.z) / 2,
      }
      this.viewer2d.select(event.objects)
    })
  }

  onVisibilityChanged(models: IBimsyncModelData[]) {
    this.viewer3d.updateModelVisibility(models)
  }

  newItemClicked() {
    combineLatest([
      this.projectService.currentProject$,
      this.userService.currentUser$,
      this.projectService.currentProjectMembers$,
      this.currentProjectId$,
      this.currentModelId$,
    ])
      .pipe(take(1))
      .subscribe(async ([project, user, projectMembers, projectId, modelId]) => {
        const screenshot3D = await this.viewer3d.getScreenshot()

        this.isDialogOpen = true
        const { itemData, taskData, isInEditMode, dueDateChanged } = await this.itemDialogService.createProjectItem(
          user,
          project,
          projectMembers
        )

        itemData.positionX = this.selectedObjectsCentroid.x || null
        itemData.positionY = this.selectedObjectsCentroid.y || null
        itemData.positionZ = this.selectedObjectsCentroid.z || null

        const modelData = {
          projectId,
          modelId,
          viewpoint: this.selectedObjects.viewpoint,
          objectId: this.selectedObjects.objects[0],
        } as IBimsyncModelItemData

        const { item } = await this.itemService.createModelItem(user, project, modelData, screenshot3D, itemData, taskData)
        this.snackbarService.showMessage(`Done creating item ${item.name} in project ${project.name}!`)
      })
  }

  openItem(item: Item) {
    if (item.data.bimsyncModelData && item.data.bimsyncModelData.viewpoint) {
      this.viewer3d.setViewpoint(item.data.bimsyncModelData.viewpoint)
      this.viewer3d.deselectAll()
      // @ts-ignore
      this.viewer3d.select(item.data.bimsyncModelData.objectId)

      combineLatest([
        this.projectService.currentProject$,
        this.projectService.currentProjectMembers$,
        this.projectService.currentUserProjectRole$,
      ])
        .pipe(take(1))
        .subscribe(([currentProject, currentProjectMembers, currentUserProjectRole]) => {
          this.itemDialogService.openItem(item, currentProject, currentProjectMembers, currentUserProjectRole, [], false)
        })
    }
  }

  showAllItemObjects(it: boolean) {
    if (it) {
      this.viewer3d.makeTheseObjectsTheOnlyOpaqueOnes(
        // @ts-ignore
        Object.values(this.itemsMap)
          .map((item) => item.bimsyncModelData && item.bimsyncModelData.objectId)
          .filter((x) => x)
      )
    } else {
      this.viewer3d.makeAllObjectsOpaque()
    }
  }

  showItemObject(item: Item) {
    if (!this.shouldShowAllItemObjects) {
      return
    }
    if (item.bimsyncModelData && item.bimsyncModelData.objectId) {
      this.viewer3d.makeTheseObjectsTheOnlyOpaqueOnes([item.bimsyncModelData.objectId])
    }
  }

  showAllObjects() {
    if (this.shouldShowAllItemObjects) {
      this.showAllItemObjects(true)
    } else {
      this.viewer3d.makeAllObjectsOpaque()
    }
  }
}

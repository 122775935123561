import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { MomentModule } from "ngx-moment"
import { PendingInvitationRemovalDialogComponent } from "./dialogs/pending-invitation-removal-dialog/pending-invitation-removal-dialog.component"
import { InvitationViewComponent } from "./invitation-view/invitation-view.component"
import { CheckdCommonModule, CheckdMaterialModule } from "@checkd-ui"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { EmailAddressSubmissionComponent } from "./email-address-submission/email-address-submission.component"
import { InvitationLoginDialogComponent } from "./dialogs/invitation-login-dialog/invitation-login-dialog.component"
import { PendingInvitationsListComponent } from "./pending-invitations-list/pending-invitations-list.component"

const routes: Routes = [{ path: ":invitationUid", component: InvitationViewComponent }]

@NgModule({
  declarations: [
    InvitationViewComponent,
    EmailAddressSubmissionComponent,
    InvitationLoginDialogComponent,
    PendingInvitationsListComponent,
    PendingInvitationRemovalDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CheckdCommonModule,
    CheckdMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
  ],
  exports: [PendingInvitationsListComponent, PendingInvitationRemovalDialogComponent],
})
export class InvitationModule {}

import { Component } from "@angular/core"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"

@Component({
  selector: "checkd-bimsync-project-list",
  templateUrl: "./bimsync-project-list.component.html",
  styleUrls: ["./bimsync-project-list.component.scss"],
})
export class BimsyncProjectListComponent {
  constructor(public bimsyncApi: BimsyncApiV2Service) {}
}

import { DragDropModule } from "@angular/cdk/drag-drop"
import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FlexLayoutModule } from "@angular/flex-layout"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS as MAT_PAGINATOR_DEFAULT_OPTIONS } from "@angular/material/legacy-paginator"
import {
  ItemActionsComponent,
  ItemCreationViewComponent,
  ItemDetailsComponent,
  ItemDetailsDialogComponent,
  ItemImageComponent,
  ItemImageGalleryComponent,
  ItemImageGalleryDialogComponent,
  ItemImagesComponent,
  ItemImageThumbnailComponent,
  ItemInfoComponent,
  ItemListComponent,
  MyItemsComponent,
} from "@items"
import { GalleryModule } from "@ks89/angular-modal-gallery"
import { TaskActionsComponent } from "@tasks"
import {
  ItemTimelineComponent,
  TimelineCommentElementComponent,
  TimelineElementComponent,
  TimelineImageElementComponent,
  TimelineStatusElementComponent,
} from "@timeline"

import { AvatarModule } from "ngx-avatars"
import { MomentModule } from "ngx-moment"
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader"
import { CheckdCommonModule, CheckdMaterialModule } from "../../dashboard/checkd-ui"
import { ItemFilterPipe } from "../../dashboard/filters/item-filter.pipe"
import { DrawingSectionComponent } from "../drawings/components/drawing-section/drawing-section.component"
import { BimsyncModule } from "../features/bimsync/bimsync.module"
import { ReportDrawingModule } from "../reports/drawing-report/report-drawing/report-drawing.module"
import { ItemCommentInputComponent } from "./item-comment-input/item-comment-input.component"
import { ItemContextmenuComponent } from "./item-contextmenu/item-contextmenu.component"
import { ItemFilterComponent } from "./item-filter/item-filter.component"
import { ItemListHeaderComponent } from "./item-list-header/item-list-header.component"
import { ItemListPaginatorComponent } from "./item-list-paginator/item-list-paginator.component"
import { ItemMetadataComponent } from "./item-metadata/item-metadata.component"
import { ItemTableColumnSelectionMenuComponent } from "./item-table-column-selection-menu/item-table-column-selection-menu.component"
import { ItemsTableActionBarComponent } from "./items-table-action-bar/items-table-action-bar.component"
import { ButtonModule } from "../next-ui/button/button.module"

@NgModule({
  imports: [
    BimsyncModule,
    CommonModule,
    CheckdMaterialModule,
    CheckdCommonModule,
    FlexLayoutModule,
    AvatarModule,
    MomentModule,
    GalleryModule,
    FormsModule,
    ReactiveFormsModule,
    ReportDrawingModule,
    DragDropModule,
    ButtonModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    ItemCreationViewComponent,
    ItemDetailsComponent,
    ItemDetailsDialogComponent,
    ItemListComponent,
    ItemActionsComponent,
    ItemInfoComponent,
    ItemImageComponent,
    ItemImageGalleryComponent,
    ItemImageGalleryDialogComponent,
    ItemImageThumbnailComponent,
    ItemImagesComponent,
    ItemTimelineComponent,
    TimelineElementComponent,
    TimelineCommentElementComponent,
    TimelineImageElementComponent,
    TimelineStatusElementComponent,
    DrawingSectionComponent,
    TaskActionsComponent,
    ItemFilterPipe,
    ItemContextmenuComponent,
    MyItemsComponent,
    ItemFilterComponent,
    ItemListPaginatorComponent,
    ItemListHeaderComponent,
    ItemMetadataComponent,
    ItemCommentInputComponent,
    ItemsTableActionBarComponent,
    ItemTableColumnSelectionMenuComponent,
  ],
  exports: [
    ItemCreationViewComponent,
    ItemDetailsComponent,
    ItemDetailsDialogComponent,
    ItemListComponent,
    ItemActionsComponent,
    ItemInfoComponent,
    ItemImageComponent,
    ItemImageGalleryComponent,
    ItemImageGalleryDialogComponent,
    ItemImageThumbnailComponent,
    ItemImagesComponent,
    ItemTimelineComponent,
    TimelineElementComponent,
    TimelineCommentElementComponent,
    TimelineImageElementComponent,
    TimelineStatusElementComponent,
    DrawingSectionComponent,
    TaskActionsComponent,
    ItemFilterPipe,
    MyItemsComponent,
    ItemTableColumnSelectionMenuComponent,
  ],
  providers: [{ provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: "standard" } }],
})
export class ItemsModule {}

import { Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "checkd-bimsync-model-info",
  templateUrl: "./bimsync-model-info.component.html",
  styleUrls: ["./bimsync-model-info.component.scss"],
})
export class BimsyncModelInfoComponent implements OnInit {
  @Input() bimsyncModel: any

  constructor() {}

  ngOnInit() {}
}

import { IBimsyncModelData, IBimsyncProjectData } from "../../../features/bimsync/models/bimsync.interface"

export const INTEGRATIONS_SUBCOLLECTION = "integrations"
export type IntegrationName = "bimsyncArena"

export interface IIntegrationData {
  bimsyncArena?: any
}

export interface IUserBimsyncIntegrationData {
  authorizationCode?: string
}

export interface IUserIntegrationData {
  bimsyncArena?: IUserBimsyncIntegrationData
}

export interface IProjectBimsyncIntegrationData {
  projects?: IBimsyncProjectData[]
  models?: IBimsyncModelData[]
}

export interface IProjectIntegrationData {
  bimsyncArena?: IProjectBimsyncIntegrationData
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { IBimsyncModelData } from "../../features/bimsync/models/bimsync.interface"

@Component({
  selector: "checkd-project-model-list",
  templateUrl: "./project-model-list.component.html",
  styleUrls: ["./project-model-list.component.scss"],
})
export class ProjectModelListComponent {
  @Input() models: IBimsyncModelData[] = []
  @Output() modelSelected = new EventEmitter()
}

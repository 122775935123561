import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { ModelInCollection } from "@models/common/collections"
import {
  IIntegrationData,
  INTEGRATIONS_SUBCOLLECTION,
  IProjectBimsyncIntegrationData,
  IProjectIntegrationData,
  IUserBimsyncIntegrationData,
  IUserIntegrationData,
} from "@models/common/integrations/integrations.interface"
import { Person } from "@models/common/person"
import { Project } from "@models/common/project"
import { IBimsyncAppCredentialsData } from "@services/native/nativebridge.interface"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

@Injectable({
  providedIn: "root",
})
export class IntegrationsService {
  public bimsyncAppCredentials$: Observable<IBimsyncAppCredentialsData> = this.db
    .doc("integrations/bimsyncArena")
    .valueChanges()
    .pipe(map((it) => it as IBimsyncAppCredentialsData))

  constructor(private db: AngularFirestore) {}

  public listenToUserBimsyncIntegration(user: Person): Observable<IUserBimsyncIntegrationData> {
    return this.listenToUserIntegrations(user).pipe(map((it) => it.bimsyncArena || {}))
  }

  public listenToProjectBimsyncIntegration(project: Project): Observable<IProjectBimsyncIntegrationData> {
    return this.listenToProjectIntegrations(project).pipe(map((it) => it.bimsyncArena || {}))
  }

  public listenToUserIntegrations(user: Person): Observable<IUserIntegrationData> {
    return this.listenFor(user)
  }

  public listenToProjectIntegrations(project: Project): Observable<IProjectIntegrationData> {
    return this.listenFor(project)
  }

  public listenFor(m: ModelInCollection): Observable<IIntegrationData> {
    return (
      this.db
        .doc(m.ref!.path)
        .collection(INTEGRATIONS_SUBCOLLECTION)
        // .valueChanges()
        .snapshotChanges()
        .pipe(
          map((it) => {
            const m = {}
            for (const i of it) {
              // @ts-ignore
              m[i.payload.doc.id] = i.payload.doc.data()
            }

            return m
          })
        )
    )
  }

  public async updateBimsyncArenaAuthorizationCode(user: Person, authorizationCode: string) {
    if (!(user.ref && user.ref.path)) {
      throw new Error(`Unable to save authorization code: No user reference`)
    }

    if (authorizationCode == null || authorizationCode.trim() === "") {
      throw new Error(`Unable to save authorization code: code was empty`)
    }

    const snap = await this.db.doc(user.ref.path).collection(INTEGRATIONS_SUBCOLLECTION).doc("bimsyncArena").get().toPromise()

    if (snap!.exists) {
      return snap!.ref.update({ authorizationCode })
    }

    return snap!.ref.set({ authorizationCode }, { merge: true })
  }
}

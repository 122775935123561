<h2>Current project</h2>
<ng-container *ngIf="bimsyncApi.currentBimsyncProject$ | async as bimsyncProject">
  <table>
    <tr>
      <td><strong>Name</strong></td>
      <td>{{bimsyncProject.name}}</td>
    </tr>
    <tr>
      <td><strong>ID</strong></td>
      <td>{{bimsyncProject.id}}</td>
    </tr>
  </table>
</ng-container>


export enum NativebridgeTopic {
  BIMSYNC_AUTHORIZATION_CODE = "BIMSYNC_AUTHORIZATION_CODE",
  BIMSYNC_APP_CREDENTIALS = "BIMSYNC_APP_CREDENTIALS",
  INFO = "INFO",
  DEBUG = "DEBUG",
  ERROR = "ERROR",
  BIMSYNC_MODEL_ID = "BIMSYNC_MODEL_ID",
  BIMSYNC_PROJECT_ID = "BIMSYNC_PROJECT_ID",
  BIMSYNC_TOKEN_DATA = "BIMSYNC_TOKEN_DATA",
  BIMSYNC_CREATE_ITEM = "BIMSYNC_CREATE_ITEM",
  BIMSYNC_ITEMS = "BIMSYNC_ITEMS",
  BIMSYNC_SHOW_ITEM = "BIMSYNC_SHOW_ITEM",
}

export interface INativebridgeMessage {
  topic: NativebridgeTopic
  data: any
}

export interface IBimsyncAuthorizationCodeData {
  code: string
}

export interface IBimsyncAppCredentialsData {
  clientId: string
  clientSecret: string
}

export interface IBimsyncTokenData {
  accessToken: string
  refreshToken: string
}

export interface InfoData {
  message: string
}

import { HostListener, Injectable } from "@angular/core"
import { BehaviorSubject, combineLatest, Observable } from "rxjs"
import { endWith, map } from "rxjs/operators"

export interface IBimMovementData {
  moveForward: number
  moveBackward: number
  moveLeft: number
  moveRight: number
  moveUp: number
  moveDown: number
}

export interface IBimRotationData {
  rotateLeft: number
  rotateRight: number
  rotateUp: number
  rotateDown: number
}

@Injectable({
  providedIn: "root",
})
export class BimNavigationService {
  mouseRotationEnabled = false
  rotationSensitivity: number = 0.05
  movementSensitivity: number = 1

  moveForward$ = new BehaviorSubject(0)
  moveBackward$ = new BehaviorSubject(0)
  moveLeft$ = new BehaviorSubject(0)
  moveRight$ = new BehaviorSubject(0)
  moveUp$ = new BehaviorSubject(0)
  moveDown$ = new BehaviorSubject(0)

  rotateLeft$ = new BehaviorSubject(0)
  rotateRight$ = new BehaviorSubject(0)
  rotateUp$ = new BehaviorSubject(0)
  rotateDown$ = new BehaviorSubject(0)

  public movement$: Observable<IBimMovementData> = combineLatest([
    this.moveForward$,
    this.moveBackward$,
    this.moveLeft$,
    this.moveRight$,
    this.moveUp$,
    this.moveDown$,
  ]).pipe(
    map(([moveForward, moveBackward, moveLeft, moveRight, moveUp, moveDown]) => ({
      moveForward,
      moveBackward,
      moveLeft,
      moveRight,
      moveUp,
      moveDown,
    }))
  )

  public rotation$: Observable<IBimRotationData> = combineLatest([
    this.rotateLeft$.pipe(endWith(0)),
    this.rotateRight$.pipe(endWith(0)),
    this.rotateUp$.pipe(endWith(0)),
    this.rotateDown$.pipe(endWith(0)),
  ]).pipe(map(([rotateLeft, rotateRight, rotateUp, rotateDown]) => ({ rotateLeft, rotateRight, rotateUp, rotateDown })))

  keyDownEvent(event: KeyboardEvent) {
    switch (event.key) {
      case "w":
        return this.moveForward$.next(this.movementSensitivity)
      case "a":
        return this.moveLeft$.next(this.movementSensitivity)
      case "s":
        return this.moveBackward$.next(this.movementSensitivity)
      case "d":
        return this.moveRight$.next(this.movementSensitivity)
      case "Control":
        return this.moveUp$.next(this.movementSensitivity)
      case " ":
        return this.moveDown$.next(this.movementSensitivity)
      default:
        return
    }
  }

  keyUpEvent(event: KeyboardEvent) {
    switch (event.key) {
      case "w":
        return this.moveForward$.next(0)
      case "a":
        return this.moveLeft$.next(0)
      case "s":
        return this.moveBackward$.next(0)
      case "d":
        return this.moveRight$.next(0)
      case "Control":
        return this.moveUp$.next(0)
      case " ":
        return this.moveDown$.next(0)
      case "Escape":
        return (this.mouseRotationEnabled = !this.mouseRotationEnabled)
      default:
        return
    }
  }

  mouseEvent(event: MouseEvent) {
    if (!this.mouseRotationEnabled) {
      return
    }

    this.rotateRight$.next(event.movementX * this.rotationSensitivity)
    this.rotateUp$.next(event.movementY * this.rotationSensitivity)
  }
}

import { Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Router } from "@angular/router"
import {
  AG_RELEVANT_COMPANY_FEATURES,
  AlertTypes,
  COLLECTIONS,
  Company,
  CompanyFeatures,
  convertStatusFilterToWorkflowStates,
  Drawing,
  Item,
  ItemData,
  LABELS,
  LegacyReport,
  Person,
  Project,
  ProjectAggregateData,
  ProjectData,
  ProjectMenuActions,
  Relation,
  Report,
  Role,
  RoleType,
  WorkflowStatusFilter,
} from "@models/common"
import { createProjectMenuActionData } from "@models/common/actions/project-menu-actions"
import { ITagCollectionData, TagCollection } from "@models/common/tag-collection"
import { uniquifyModels } from "@models/common/utilities"
import { CloudFunctionsService } from "@services/cloud-functions.service"
import { LoggingService } from "@services/logging.service"
import { TagCollectionService } from "@services/tag-collection.service"
import { naturalSortObjectsByProperty } from "@services/utilities"
import firebase from "firebase/compat/app"
import { BehaviorSubject, combineLatest, EMPTY, from as observableFrom, Observable, of as observableOf, ReplaySubject } from "rxjs"
import { distinctUntilChanged, map, mergeMap, share, shareReplay, switchMap, tap } from "rxjs/operators"
import { IProjectDuplicationSettings } from "../../../../functions/src/projects/project-duplication.interface"
import { IBimsyncProjectData } from "../features/bimsync/models/bimsync.interface"
import { ItemService } from "./item.service"
import { ModelService } from "./model.service"
import { RelationService } from "./relation.service"
import { RoleHandlerService } from "./role-handler.service"
import { TimelineService } from "./timeline.service"
import { UserService } from "./user.service"
import firestore = firebase.firestore

@Injectable()
export class ProjectService {
  private _currentProjectUid$ = new ReplaySubject<string>(1)
  private _isCurrentlyInProjectPattern = new RegExp("^/projects/[A-Za-z0-9]{15,}")

  public currentProject$: Observable<Project> = this._currentProjectUid$.pipe(
    distinctUntilChanged(),
    switchMap((projectUid) => {
      return projectUid != null ? this.listenToUid(projectUid) : EMPTY
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  // This was added to, as the name implies, detect whether the user is currently inside a project.
  // It is necessary due to how this service sets the current project (by calling setCurrentProject).
  // Calling that method explicitly isn't necessarily a problem in itself, however, when it was implemented, nobody called it with an
  // empty string to 'clear' out the current project when navigating away from a project, thus, after a project has been selected and
  // the user navigates away, the project is still active in this service.
  // A quick refactor attempt to automatically/reactively set the current project based on router state ended up with all sorts of problems
  // due to listeners elsewhere assuming the observables in this service never completes.
  public isInProjectTest(url: string): boolean {
    return this._isCurrentlyInProjectPattern.test(url)
  }

  currentProjectHasCompanyFeature(feature: CompanyFeatures): Observable<boolean> {
    return this.currentProject$.pipe(map((project) => (project?.aggregateData?.companyFeatures ?? []).includes(feature)))
  }

  public currentProjectDrawings$: Observable<Drawing[]> = this.currentProject$.pipe(
    switchMap((project) => this.listenToDrawings(project)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public currentProjectMembers$: Observable<Person[]> = this.currentProject$.pipe(
    switchMap((project) => this.listenToPeople(project)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public currentProjectItems$: Observable<Item[]> = this.currentProject$.pipe(
    switchMap((project) => this.listenToAllItems(project)),
    share()
  )

  public currentProjectLegacyReports$: Observable<LegacyReport[]> = this.currentProject$.pipe(
    switchMap((project) => this.listenToLegacyReports(project)),
    shareReplay(1)
  )

  public currentProjectFieldReports$: Observable<Report[]> = this.currentProject$.pipe(
    switchMap((project) => this.listenToReports(project)),
    shareReplay(1)
  )

  public currentUserProjectRole$: Observable<Role> = combineLatest([this.userService.currentUser$, this.currentProject$]).pipe(
    switchMap((userAndProject) => {
      const [currentUser, currentProject] = userAndProject

      return observableFrom(this.roleHandlerService.getPersonProjectRole(currentUser, currentProject))
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  public currentProjectTagCollections$: Observable<TagCollection[]> = this.currentProject$.pipe(
    switchMap((project) => this.tagCollectionService.listenFor(project))
  )

  public projectItemsChunkSize = 100
  public currentProjectItemsLimit$ = new BehaviorSubject(this.projectItemsChunkSize)
  public currentProjectItemsChunkSize$ = new BehaviorSubject(0)
  public isLoadingProjectItems$ = new BehaviorSubject(true)

  public initialStatusFilter: WorkflowStatusFilter = {
    OPEN: true,
    DELEGATED: true,
    INPROGRESS: true,
    FIXED: true,
    CLOSED: false,
  }

  public statusFilter$ = new BehaviorSubject(this.initialStatusFilter)
  public currentWorkflowStates$: Observable<string[]> = this.statusFilter$.pipe(map(convertStatusFilterToWorkflowStates))

  public currentProjectItemsNew$: Observable<Item[]> = combineLatest([
    this.currentProject$,
    this.currentProjectItemsLimit$,
    this.currentWorkflowStates$,
  ]).pipe(
    tap((_) => {
      this.isLoadingProjectItems$.next(true)
    }),
    switchMap(([project, limit, statusFilter]) => {
      return statusFilter && statusFilter.length > 0
        ? this.db
            .collection(COLLECTIONS.ITEMS, (ref) => {
              return ref
                .where("aggregateData.projectUid", "==", project.uid)
                .where("status", "in", statusFilter)
                .orderBy("updatedAt", "desc")
                .limit(limit)
            })
            .snapshotChanges()
        : observableOf([])
    }),
    tap((it) => {
      this.currentProjectItemsChunkSize$.next(it.length)
    }),
    // @ts-ignore
    map((it) => it.filter((foo) => !foo.payload.doc.data().disabled)),
    map((it) => it.map((foo) => new Item(foo.payload.doc.data() as ItemData, foo.payload.doc.id, foo.payload.doc.ref))),
    tap((_) => {
      if (this.currentProjectItemsChunkSize$.value >= this.currentProjectItemsLimit$.value) {
        this.currentProjectItemsLimit$.next(this.currentProjectItemsLimit$.value + this.projectItemsChunkSize)
      } else {
        this.isLoadingProjectItems$.next(false)
      }
    })
  )

  constructor(
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private modelService: ModelService,
    private relationService: RelationService,
    public itemService: ItemService,
    private userService: UserService,
    private timelineService: TimelineService,
    private roleHandlerService: RoleHandlerService,
    private tagCollectionService: TagCollectionService,
    private loggingService: LoggingService,
    private cfService: CloudFunctionsService,
    private router: Router
  ) {}

  public hasDrawingsFeature(project: Project) {
    return (
      project &&
      project.aggregateData &&
      project.aggregateData.companyFeatures &&
      project.aggregateData.companyFeatures.includes(CompanyFeatures.DRAWINGS)
    )
  }

  public setCurrentProject(projectUid: string) {
    this._currentProjectUid$.next(projectUid)
    this.loggingService.currentProjectUid$.next(projectUid)
  }

  listenToUid(projectUid: string): Observable<Project> {
    return this.modelService.listenTo(COLLECTIONS.PROJECTS, projectUid) as Observable<Project>
  }

  /**
   * @deprecated
   */
  listenTo(project: Project): Observable<Project> {
    return this.listenToUid(project.uid)
  }

  listenToDrawings(project: Project): Observable<Drawing[]> {
    return this.relationService.listenToTargets(project, COLLECTIONS.DRAWINGS) as Observable<Drawing[]>
  }

  listenToItems(project: Project): Observable<Item[]> {
    return this.relationService.listenToTargets(project, COLLECTIONS.ITEMS) as Observable<Item[]>
  }

  listenToDrawingItems(project: Project): Observable<Item[]> {
    return this.listenToDrawings(project).pipe(
      map((drawings) => drawings.map((drawing) => this.relationService.listenToTargets(drawing, COLLECTIONS.ITEMS))),
      // @ts-ignore
      mergeMap((drawingItems) => this.relationService.combineLatestOrEmptyList(drawingItems)),
      // @ts-ignore
      map((drawingItems: Item[]) => uniquifyModels([].concat(...drawingItems)))
    )
  }

  listenToAllItems(project: Project): Observable<Item[]> {
    return combineLatest([this.listenToItems(project), this.listenToDrawingItems(project)]).pipe(
      // @ts-ignore
      map((itemLists) => [].concat(...itemLists)),
      map(uniquifyModels),
      map((items) => this.orderItemsByUpdatedAt(items))
    )
  }

  listenToReports(project: Project): Observable<Report[]> {
    return this.relationService
      .listenToTargets(project, COLLECTIONS.REPORTS)
      .pipe(map((reports) => reports.sort((a, b) => b.createdAt - a.createdAt))) as Observable<Report[]>
  }

  listenToLegacyReports(project: Project): Observable<LegacyReport[]> {
    return this.relationService
      .listenToTargets(project, COLLECTIONS.LEGACY_REPORTS)
      .pipe(map((reports) => reports.sort((a, b) => b.createdAt - a.createdAt))) as Observable<LegacyReport[]>
  }

  listenToPeople(project: Project, withLabels?: string[]): Observable<Person[]> {
    return this.relationService
      .listenToTargets(project, COLLECTIONS.PEOPLE, withLabels)
      .pipe(map((members) => naturalSortObjectsByProperty(members, "name"))) as Observable<Person[]>
  }

  listenToCompany(project: Project): Observable<Company> {
    return this.relationService
      .listenToTargets(project, COLLECTIONS.COMPANIES)
      .pipe(map((companies) => (companies ? companies[0] : EMPTY))) as Observable<Company>
  }

  listenToAlerts(project: Project, forType: AlertTypes) {
    return this.db.doc(project.ref!.path).collection(COLLECTIONS.ALERTS).doc(forType).snapshotChanges()
  }

  orderItemsByUpdatedAt(items: Item[]) {
    return items.sort((a: Item, b: Item): number => {
      return (b.updatedAt || 0) - (a.updatedAt || 0)
    })
  }

  getPeople(project: Project) {
    return project.getPeople()
  }

  async addCreator(batch: firestore.WriteBatch, project: Project, creator: Person) {
    if (creator != null) {
      project.batchAdd(batch, creator, [Relation.LABELS.CREATED_BY])
      creator.batchAdd(batch, project, [Relation.LABELS.CREATOR])
    }

    return project
  }

  async addOwner(batch: firestore.WriteBatch, project: Project, owner: Person) {
    if (owner != null) {
      project.batchAdd(batch, owner, [Relation.LABELS.OWNED_BY])
      owner.batchAdd(batch, project, [Relation.LABELS.OWNER])
    }

    return project
  }

  async addToCompany(batch: firestore.WriteBatch, project: Project, company: Company) {
    company.batchAdd(batch, project, [Relation.LABELS.CONTAINS])
    project.batchAdd(batch, company, [Relation.LABELS.CONTAINED_BY])

    return project
  }

  addCompanyProjectFeatures(batch: firestore.WriteBatch, company: Company, project: Project) {
    // @ts-ignore
    const companyFeatures = (company.features || []).filter((feature) => AG_RELEVANT_COMPANY_FEATURES.includes(CompanyFeatures[feature]))

    project.batchUpdateAggregateData(batch, { companyFeatures })
  }

  async createProject(creator: Person, projectData: ProjectData, company: Company, reportTags: string[] = []) {
    const batch = this.db.firestore.batch()
    const project = Project.batchCreate(batch, Project, projectData)
    this.addCreator(batch, project, creator)
    this.addOwner(batch, project, creator)
    this.addToCompany(batch, project, company)
    await this.timelineService.projectCreated(batch, creator, project)

    const aggregateData: ProjectAggregateData = {
      // @ts-ignore
      companyName: company.name || null,
      // @ts-ignore
      companyUid: company.uid || null,
      // @ts-ignore
      projectCreatorName: creator.name || null,
      // @ts-ignore
      projectCreatorUid: creator.uid || null,
      // @ts-ignore
      projectCreatorEmail: creator.email || null,
    }
    project.batchUpdateAggregateData(batch, aggregateData)

    this.addCompanyProjectFeatures(batch, company, project)

    const tagCollectionData = {
      name: COLLECTIONS.REPORTS,
      tags: reportTags,
    } as ITagCollectionData

    this.tagCollectionService.addTagCollectionBatch(batch, project, tagCollectionData)

    await batch.commit()

    return project
  }

  // TODO move this to a HTTPS cloud function
  async disableProject(disabler: Person, project: Project): Promise<void> {
    const projectDrawings = await project.getDrawings()
    const allProjectItems = await project.getItems()
    const projectReports = await project.getReports()
    const projectLegacyReports = await project.getLegacyReports()
    const allProjectTasks = await Promise.all(allProjectItems.map((item) => item.getTask())).then((tasks) => tasks.filter((task) => task))

    await Promise.all([
      Promise.all(projectDrawings.map((drawing) => drawing.disable())),
      Promise.all(allProjectTasks.map((task) => task.disable())),
      Promise.all(allProjectItems.map((item) => item.disable())),
      Promise.all(projectReports.map((report) => report.disable())),
      Promise.all(projectLegacyReports.map((legacyReport) => legacyReport.disable())),
    ])

    const batch = this.db.firestore.batch()
    project.batchDisable(batch)
    this.timelineService.projectDisabled(batch, disabler, project)

    return batch.commit()
  }

  async toggleArchivedState(archiver: Person, project: Project, archivedState: boolean) {
    return this.cfService.updateProjectArchivedState({ projectUid: project.uid, archived: archivedState })
  }

  async removeProjectMember(project: Project, remover: Person, removed: Person) {
    const batch = this.db.firestore.batch()

    const [relation, inverseRelation] = await Promise.all([
      this.relationService.getRelation(project, removed),
      this.relationService.getRelation(removed, project),
    ])

    // @ts-ignore
    batch.update(relation.ref, { disabled: true, labels: [LABELS.DEFAULT] })
    // @ts-ignore
    batch.update(inverseRelation.ref, { disabled: true, labels: [LABELS.DEFAULT] })

    this.timelineService.projectMemberRemoved(batch, remover, removed, project)
    await batch.commit()
  }

  getMenuOptions(project: Project, personProjectRole: Role, company: Company, userCompanyRole: Role) {
    const menuOptions = []
    let archiveProjectAdded = false

    const isProjectOwnedByCompany = (project?.aggregateData?.companyUid || "-") === (company?.uid ?? "")
    const isCompanyAdministrator = userCompanyRole?.roleType === RoleType.COMPANY_ADMINISTRATOR
    const isProjectOwner = personProjectRole?.roleType === RoleType.PROJECT_OWNER

    if (project?.archived) {
      if (isProjectOwner || (isProjectOwnedByCompany && isCompanyAdministrator)) {
        if (company?.features.includes(CompanyFeatures.PROJECT_ARCHIVING) || true) {
          menuOptions.push(createProjectMenuActionData(project, ProjectMenuActions.UNARCHIVE_PROJECT))
        }
      }

      return menuOptions
    }

    if (personProjectRole == null && isCompanyAdministrator) {
      menuOptions.push(createProjectMenuActionData(project, ProjectMenuActions.JOIN_PROJECT))

      return menuOptions
    }

    if (personProjectRole.canDeleteTarget()) {
      menuOptions.push(createProjectMenuActionData(project, ProjectMenuActions.REMOVE_PROJECT))

      if (company != null && company.features.includes(CompanyFeatures.PROJECT_ARCHIVING)) {
        menuOptions.push(createProjectMenuActionData(project, ProjectMenuActions.ARCHIVE_PROJECT))
        archiveProjectAdded = true
      }
    }

    if (isProjectOwnedByCompany && !archiveProjectAdded && isCompanyAdministrator) {
      menuOptions.push(createProjectMenuActionData(project, ProjectMenuActions.ARCHIVE_PROJECT))
    }

    if (!personProjectRole.canReadTarget() && isCompanyAdministrator) {
      menuOptions.push(createProjectMenuActionData(project, ProjectMenuActions.JOIN_PROJECT))
    }

    if (
      personProjectRole.canReadTarget() &&
      isCompanyAdministrator &&
      !company.lockedByCheckd &&
      project.aggregateData?.companyUid === company.uid
    ) {
      menuOptions.push(createProjectMenuActionData(project, ProjectMenuActions.DUPLICATE_PROJECT))
    }

    return menuOptions
  }

  async addBimsyncProject(checkdProject: Project, bimsyncProject: IBimsyncProjectData) {
    // @ts-ignore
    const snap = await this.db.doc(checkdProject.ref.path).collection("integrations").doc("bimsyncArena").get().toPromise()

    const batch = this.db.firestore.batch()

    // NB: We're using ref.set without merge: true here to enforce only one project connection.
    //     Originally we supported one-to-many connections from a CHECKD project to a BimSync project,
    //     but we're disabling this for now in order to simplify things somewhat.

    // @ts-ignore
    await batch.set(snap.ref, {
      projects: { [bimsyncProject.id]: bimsyncProject },
    })

    checkdProject.batchUpdate(batch, {
      bcfGuid: bimsyncProject.id,
    })

    return batch.commit()
  }

  addProjectMember(batch: firestore.WriteBatch, project: Project, member: Person, labels: string[] = [LABELS.DEFAULT]) {
    // @ts-ignore
    project.batchAdd(batch, member, Relation.invertLabels(labels))
    member.batchAdd(batch, project, labels)
  }

  addComapnyAdminToProject(user: Person, project: Project) {
    const batch = this.db.firestore.batch()
    this.addProjectMember(batch, project, user, [LABELS.ADMINISTRATOR])
    this.timelineService.projectMemberAdded(batch, user, user, RoleType.PROJECT_ADMINISTRATOR, project)

    return batch.commit()
  }

  async duplicateProject(projectToDuplicate: string, duplicationSettings: IProjectDuplicationSettings) {
    await this.cfService.duplicateProject(projectToDuplicate, duplicationSettings)
  }
}

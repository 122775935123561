import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"

type PanelSelectionTypes = "storey" | "info"

@Component({
  selector: "checkd-bimsync-action-bar",
  templateUrl: "./bimsync-action-bar.component.html",
  styleUrls: ["./bimsync-action-bar.component.scss"],
})
export class BimsyncActionBarComponent {
  // two-way binding [(viewer2dVisible)]
  _viewer2dVisible: boolean
  @Input()
  get viewer2dVisible() {
    return this._viewer2dVisible
  }
  set viewer2dVisible(value: boolean) {
    this._viewer2dVisible = value
    this.viewer2dVisibleChange.emit(this._viewer2dVisible)
  }
  @Output() viewer2dVisibleChange = new EventEmitter<boolean>()

  // two-way binding [(viewer3dVisible)]
  _viewer3dVisible: boolean
  @Input()
  get viewer3dVisible() {
    return this._viewer3dVisible
  }
  set viewer3dVisible(value: boolean) {
    this._viewer3dVisible = value
    this.viewer3dVisibleChange.emit(this._viewer3dVisible)
  }
  @Output() viewer3dVisibleChange = new EventEmitter<boolean>()

  // two-way binding [(viewerDirectionHorizontal)]
  _viewerDirectionHorizontal: boolean
  @Input()
  get viewerDirectionHorizontal() {
    return this._viewerDirectionHorizontal
  }
  set viewerDirectionHorizontal(value: boolean) {
    this._viewerDirectionHorizontal = value
    this.viewerDirectionHorizontalChange.emit(this._viewerDirectionHorizontal)
  }
  @Output() viewerDirectionHorizontalChange = new EventEmitter<boolean>()

  panelSelection: PanelSelectionTypes | null = null
  containerCollapsed = true

  constructor(public bimsyncApi: BimsyncApiV2Service) {
    return
  }

  panelSelectionButtonClicked(button: PanelSelectionTypes) {
    if (this.panelSelection === button) {
      this.containerCollapsed = !this.containerCollapsed

      return
    }

    this.panelSelection = button
    this.containerCollapsed = false
  }

  toggleMapsShown(mapType: "2d" | "3d") {
    // tslint:disable-next-line:switch-default
    switch (mapType) {
      case "2d":
        if (!this.viewer3dVisible) {
          return
        }
        this.viewer2dVisible = !this.viewer2dVisible
        break
      case "3d":
        if (!this.viewer2dVisible) {
          return
        }
        this.viewer3dVisible = !this.viewer3dVisible
        break
    }
  }
}

export abstract class BimsyncViewBaseClass {
  // backing fields for accessors
  private _viewerDirectionHorizontal: boolean
  private _viewer2dVisible: boolean
  private _viewer3dVisible: boolean

  get viewerDirectionHorizontal(): boolean {
    return this._viewerDirectionHorizontal
  }
  set viewerDirectionHorizontal(value: boolean) {
    this._viewerDirectionHorizontal = value
    this.triggerRedraw()
  }

  get viewer2dVisible(): boolean {
    return this._viewer2dVisible
  }
  set viewer2dVisible(value: boolean) {
    this._viewer2dVisible = value
    this.triggerRedraw()
  }

  get viewer3dVisible(): boolean {
    return this._viewer3dVisible
  }
  set viewer3dVisible(value: boolean) {
    this._viewer3dVisible = value
    this.triggerRedraw()
  }

  protected constructor(viewerDirectionHorizontal: boolean, viewer2dVisible: boolean, viewer3dVisible: boolean) {
    this._viewerDirectionHorizontal = viewerDirectionHorizontal
    this._viewer2dVisible = viewer2dVisible
    this._viewer3dVisible = viewer3dVisible
  }

  // TODO: Find a better way to do this
  triggerRedraw() {
    window.dispatchEvent(new Event("resize"))
  }
}

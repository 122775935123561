import { Component } from "@angular/core"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"

@Component({
  selector: "checkd-bimsync-project-info",
  templateUrl: "./bimsync-project-info.component.html",
  styleUrls: ["./bimsync-project-info.component.scss"],
})
export class BimsyncProjectInfoComponent {
  constructor(public bimsyncApi: BimsyncApiV2Service) {}
}

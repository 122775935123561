import { InvitationAggregateData } from "./aggregate-data"
import { Person } from "./person"
import { BaseModelData } from "./base-model.interface"

export enum InvitationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  NONE = "NONE",
}

export enum InvitationType {
  TASK = "TASK", // TODO remove this after updating the apps
  PROJECT = "PROJECT",
  PROJECT_AND_TASK = "PROJECT_AND_TASK",
  COMPANY = "COMPANY",
  ENTERPRISE_COMPANY = "ENTERPRISE_COMPANY",
  NONE = "NONE",
}

export interface PersonWithRoles {
  person: Person
  roles: string[]
}

export interface EmailWithRoles {
  email: string
  roles: string[]
}

export interface SmsInviteWithRole {
  phone: string
  message: string
  role: string
}

export interface Invitations {
  people: PersonWithRoles[]
  emails: EmailWithRoles[]
}

export interface InvitationData extends BaseModelData {
  sourceUid: string // UID of the person inviting
  targetEmail?: string
  // targetPhoneNumber needs to be in a valid E.164 format
  targetPhoneNumber?: string
  sentSMS?: boolean
  targetName?: string
  targetRoleTitle?: string
  targetUid?: string
  invitationType?: InvitationType
  deliveryMethod?: "email" | "sms"
  message?: string
  triggerEmail?: boolean

  status?: InvitationStatus

  // NB: 'resources' must be a DocumentReference array, but we can't
  // define it as such until the typings for cloud/client firestore
  // API's are sorted out. Redefine when upgrading the libraries.
  resources: any[]
  active?: boolean
  labels?: string[]
  aggregateData?: InvitationAggregateData

  loginMethods?: string[]
}

export interface IInvitationAcceptData {
  userUid: string
  invitationUid: string
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core"
import {
  ButtonEvent,
  ButtonsStrategy,
  ButtonType,
  CurrentImageConfig,
  GridLayout,
  Image as GalleryImage,
  ModalGalleryRef,
  ModalGalleryService,
  ModalImage,
  PlainGalleryConfig,
  PlainGalleryStrategy,
  PlainImage,
  PlainLibConfig,
} from "@ks89/angular-modal-gallery"
import { ButtonsConfig } from "@ks89/angular-modal-gallery/lib/model/buttons-config.interface"
import { COLLECTIONS, Image, Role, RoleType } from "@models/common"
import { FilestackService, SnackbarService } from "@services"
import { DateFormatPipe, FromUnixPipe } from "ngx-moment"
import { Subscription } from "rxjs"
import { DialogService } from "../../dialogs/dialog.service"

class GImage extends GalleryImage {
  constructor(id: number, modal: ModalImage, plain: PlainImage, public image: Image) {
    super(id, modal, plain)
  }
}

@Component({
  selector: "item-images",
  templateUrl: "./item-images.component.html",
  styleUrls: ["./item-images.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemImagesComponent implements OnChanges, OnDestroy {
  @Input() images: Image[]
  @Input() imageWidth = "auto"
  @Input() imageHeight = "70px"
  @Input() shadowVisible = false
  @Input() userProjectRole: Role
  @Input() userItemRole: Role

  @Output() removeImage = new EventEmitter<Image>()

  canDeleteImages: boolean = false
  galleryImages: GalleryImage[] = []
  galleryButtonsConfig: ButtonsConfig

  readonly galleryId = this.getRandomInt(1, 1000000)
  readonly subscriptions = new Subscription()

  libConfigPlainGallery: PlainLibConfig = {
    plainGalleryConfig: {
      strategy: PlainGalleryStrategy.GRID,
      layout: new GridLayout({ width: this.imageWidth, height: this.imageHeight }, { length: 10, wrap: true }),
    } as PlainGalleryConfig,
  }

  onShow(id: number, index: number): void {
    const currentImage = this.galleryImages[index]

    if (!currentImage) {
      return
    }

    const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
      id,
      images: this.galleryImages,
      currentImage,
      libConfig: {
        buttonsConfig: this.galleryButtonsConfig,
      },
    }) as ModalGalleryRef

    this.subscriptions.add(
      dialogRef.buttonAfterHook$.subscribe((event: ButtonEvent) => {
        console.log("OUTPUT - buttonAfterHook$:", event)
        if (!event || !event.button) {
          return
        }

        switch (event.button.type) {
          // NB: We cannot use the built-in DELETE ButtonType as we
          // need to show a confirmation dialog before emitting to the parent component.
          // There is no way to prevent showing the next image until after the dialog is closed.
          case ButtonType.CUSTOM: {
            switch (event.button.className) {
              case "delete-image": {
                return this.onRemoveImage((event.image as unknown as GImage).image)
              }
              default: {
                return
              }
            }
          }
          default: {
            return
          }
        }
      })
    )
  }

  currentImageConfig: CurrentImageConfig = {
    navigateOnClick: false,
    downloadable: true,
  }

  constructor(
    private filestack: FilestackService,
    private snackbarService: SnackbarService,
    private modalGalleryService: ModalGalleryService,
    private dialogService: DialogService
  ) {
    this.setupGalleryButtons()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["userProjectRole"] != null || changes["userItemRole"] != null) {
      this.updateImageDeletionPermissions()
      this.setupGalleryButtons()
    }
    if (changes["images"] != null) {
      this.updateGalleryImages()
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  setupGalleryButtons() {
    this.galleryButtonsConfig = {
      visible: true,
      strategy: ButtonsStrategy.CUSTOM,
      // @ts-ignore
      buttons: [
        this.canDeleteImages ? { className: "delete-image", type: ButtonType.CUSTOM } : null,
        { className: "close-image", type: ButtonType.CLOSE },
      ].filter((it) => it),
    }
  }

  updateImageDeletionPermissions() {
    const creatorCheck: boolean = this.userItemRole?.canDeleteTargetDocuments(COLLECTIONS.IMAGES) ?? false
    const ownerOrAdminCheck: boolean = [RoleType.PROJECT_OWNER, RoleType.PROJECT_ADMINISTRATOR].includes(this.userProjectRole?.roleType)
    this.canDeleteImages = creatorCheck || ownerOrAdminCheck
  }

  get hasImages() {
    return this.images?.length > 0 ?? false
  }

  updateGalleryImages() {
    // @ts-ignore
    this.galleryImages = (this.images || [])
      // @ts-ignore
      .map((image, index) => {
        if (image.data.url) {
          return new GImage(
            index,
            {
              img: image.data.url,
              description: `Uploaded: ${this.formatTimestamp(image.data.createdAt)}`,
            },
            {
              img: this.filestack.getThumbnailUrl(image.data.url, 150),
            },
            image
          )
        }
      })
      .filter((image) => image)
  }

  formatTimestamp(timestamp: any) {
    if (isNaN(timestamp as any)) {
      return timestamp
    }
    const fromUnix = new FromUnixPipe().transform(timestamp / 1000)
    const dateFormat = new DateFormatPipe().transform(fromUnix, "DD-MM-YYYY HH:mm")

    return dateFormat
  }

  getRandomInt(min: number, max: number) {
    const cMin = Math.ceil(min)
    const fMax = Math.floor(max)

    return Math.floor(Math.random() * (fMax - cMin)) + cMin
  }

  async onRemoveImage(image: Image) {
    if (!this.canDeleteImages) {
      return this.snackbarService.showMessage("You do not have permissions to delete this image.")
    }

    const result = await this.dialogService.confirm(
      `Are you sure you want to remove ${image.name}?`,
      "The image will be removed for all users."
    )
    if (!result) {
      return
    }

    this.removeImage.emit(image)
    this.modalGalleryService.close(this.galleryId, true)
  }
}

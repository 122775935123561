<div class="content-wrapper">

  <h3>Current model:</h3>

  <table *ngIf="bimsyncModel">
    <tr>
      <td><strong>Name</strong></td>
      <td>{{bimsyncModel.name}}</td>
    </tr>
    <tr>
      <td><strong>ID</strong></td>
      <td>{{bimsyncModel.id}}</td>
    </tr>
  </table>

</div>

<button
  aria-label="Toggle model selection menu"
  [matMenuTriggerFor]="modelSelectionMenu"
  mat-icon-button>
  <mat-icon>visibility</mat-icon>
</button>

<mat-menu #modelSelectionMenu="matMenu">
  <mat-list>
    <mat-list-item *ngFor="let model of bimsyncModels; let i = index; let last = last" matRipple (click)="onItemClicked(i)">
      <mat-icon matListIcon class="visibility-icon" [style.color]="model.isVisible ? '#6699f6' : null">
        {{model.isVisible ? 'visibility' : 'visibility_off'}}
      </mat-icon>
      <p matLine>{{model?.name}}</p>
      <mat-divider *ngIf="!last"></mat-divider>
    </mat-list-item>
  </mat-list>
</mat-menu>

import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { FirebaseApp } from "@angular/fire/compat"
import { MatDialog } from "@angular/material/dialog"
import { MatSidenav } from "@angular/material/sidenav"
import { ActivatedRoute, Router } from "@angular/router"
import { Company, CompanyFeatures, LABELS, Role } from "@models/common"
import { ICheckdAgreement } from "@models/common/agreements.interface"
import { AgreementsService } from "@services/agreements.service"
import { AnalyticsService } from "@services/analytics.service"
import { FirebaseAuthService } from "@services/firebase-auth.service"
import { NavigationService } from "@services/navigation.service"
import { ProjectService } from "@services/project.service"
import { SideNavService } from "@services/side-nav.service"
import { UserService } from "@services/user.service"
import { Observable, Subscription } from "rxjs"
import { map } from "rxjs/operators"
import { DialogService } from "./dialogs/dialog.service"
import { TermsAndConditionsDialogComponent } from "./dialogs/terms-and-conditions-dialog/terms-and-conditions-dialog.component"

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[]

  @ViewChild("snav", { static: true }) snav: MatSidenav

  constructor(
    private agreementsService: AgreementsService,
    public userService: UserService,
    private router: Router,
    private analyticsService: AnalyticsService,
    public navigationService: NavigationService,
    public sideNavService: SideNavService,
    private firebase: FirebaseApp,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private dialogService: DialogService,
    private firebaseAuth: FirebaseAuthService,
    private dialog: MatDialog
  ) {}

  readonly currentCompanyLogo$ = this.userService.listenToCurrentMainCompany().pipe(map((company) => company?.logo || ""))

  // readonly formsLibraryActive$ = this.navigationService.pathComponents$.pipe(
  //   map(
  //     (pathComponents) =>
  //       pathComponents.indexOf("forms") === 0 && (pathComponents.indexOf("library") === 1 || pathComponents.indexOf("templates") === 1)
  //   )
  // )

  // readonly companyFormsActive$ = this.navigationService.pathComponents$.pipe(
  //   map((pathComponents) => pathComponents.indexOf("forms") === 0 && pathComponents.indexOf("company") === 1)
  // )

  // readonly memberCompaniesActive$ = this.navigationService.pathComponents$.pipe(
  //   map((pathComponents) => pathComponents.indexOf("forms") === 0 && pathComponents.indexOf("member-companies") === 1)
  // )

  ngOnInit() {
    this.setupSubscriptions()
    this.analyticsService.initializeIntercom()
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.userService.listenToCurrentUserAndMainCompany().subscribe(({ user, company }) => {
        this.analyticsService.updateIntercomData(user, company)
      }),

      this.sideNavService.sideNavButtonClicked$.subscribe((value) => {
        // tslint:disable-next-line:switch-default
        switch (value) {
          case "open":
            this.snav.open()
            break
          case "close":
            this.snav.close()
            break
          case "toggle":
            this.snav.toggle()
            break
        }
      }),

      this.agreementsService.currentUserAgreementsStatus$.subscribe((status) => {
        const agreementsToUpdate = [...status.notAgreedTo, ...status.notUpToDate]

        if (agreementsToUpdate && agreementsToUpdate.length > 0) {
          this.openTermsAndConditionsDialog(agreementsToUpdate)
        }
      }),
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  openTermsAndConditionsDialog(agreements: ICheckdAgreement[]) {
    this.dialog.open(TermsAndConditionsDialogComponent, { disableClose: true, data: agreements })
  }

  currentCompanyHasCompanyInsightsFeature(currentCompany: Company) {
    return currentCompany && (currentCompany.features || []).includes(CompanyFeatures.COMPANY_INSIGHTS)
  }

  readonly shouldDisplayFormsMenuInSidebar$: Observable<boolean> = this.userService.currentCompanyFeatures$.pipe(
    map((currentCompanyFeatures) => {
      // The current company needs at least one of these features to be able to view the forms menu in the sidebar
      const authorizedFeatures = [CompanyFeatures.FORMS_LIBRARY, CompanyFeatures.MEMBER_TEMPLATES_VIEWER, CompanyFeatures.FORMS]

      return currentCompanyFeatures.some((feature) => authorizedFeatures.includes(feature))
    })
  )

  readonly currentCompanyHasFormsFeature$: Observable<boolean> = this.userService.currentCompanyFeatures$.pipe(
    map((features) => features.includes(CompanyFeatures.FORMS))
  )

  userIsCompanyAdmin(userCompanyRole: Role): boolean {
    return userCompanyRole && (userCompanyRole.labels || []).includes(LABELS.ADMINISTRATOR)
  }
}

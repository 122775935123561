// @ts-nocheck
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"
import { BimNavigationService } from "@services/bim-navigation.service"
import { combineLatest, Subscription } from "rxjs"
import { IBimsyncModelData } from "../../models/bimsync.interface"
import { ItemDialogService } from "../../../../items/item-dialog.service"

declare const bimsync: any
declare const $: any

@Component({
  selector: "checkd-bimsync-viewer3d",
  templateUrl: "./bimsync-viewer3d.component.html",
  styleUrls: ["./bimsync-viewer3d.component.scss"],
})
export class BimsyncViewer3dComponent implements OnInit, OnDestroy {
  bimsyncViewer3d
  subscriptions: Subscription[] = []
  currentModel: Partial<IBimsyncModelData> = null

  @Output() objectsSelected = new EventEmitter<{ objects: string[]; viewpoint: any }>()

  constructor(public bimsyncApi: BimsyncApiV2Service, public bimNavigation: BimNavigationService) {}

  ngOnInit() {
    this.setupSubscriptions()
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.bimNavigation.movement$.subscribe((movementData) => {
        if (this.bimsyncViewer3d) {
          this.bimsyncViewer3d.viewer("navigate", movementData)
        }
      }),
      this.bimNavigation.rotation$.subscribe((rotationData) => {
        if (this.bimsyncViewer3d) {
          this.bimsyncViewer3d.viewer("navigate", rotationData)
        }
      }),
      combineLatest([this.bimsyncApi.currentBimsyncProjectModel$, this.bimsyncApi.currentViewer3dUrl$]).subscribe(([model, url]) => {
        this.currentModel = model
        this.loadViewer3d(url)
      }),
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  loadViewer3d(viewerUrl: string) {
    bimsync.load(["viewer-ui"])
    bimsync.setOnLoadCallback(() => {
      // TODO Check if we can use a ViewChild instead of jQuery selector
      this.bimsyncViewer3d = $("#bimsync-viewer-3d")

      this.bimsyncViewer3d
        .viewer({
          defaultColor: "red",
          selectedColor: "blue",
          translucentOpacity: 0.05,
          enableTouch: true,
          enableClippingPlaneWidget: true,
        })
        // Remove previous models from the viewer
        .viewer("modelInfo", (modelInfos) => {
          for (const model of modelInfos) {
            this.bimsyncViewer3d.viewer("unloadModel", model.id)
          }
        })
        .bind("viewer.load", (_) => this.afterViewer3dLoad())
        .bind("viewer.error", (type) => this.onViewer3dError(type))
        .bind("viewer.select", (_, selectedObjects) => this.onObjectsSelected(selectedObjects))
        .viewer("loadUrl", viewerUrl)
        .viewer("hideAll")
        .viewer("show", this.currentModel.id)
        .viewerUI({
          // enableContextMenu: true,
          // enableTouch: true,
          // enableJoystick: true,
          // joystickHidden: false,
          viewer2dId: "bimsync-viewer-2d",
          enableViewer2dIntegration: true,
          // showViewer2dStoreySelect: true,
        })
    })
  }

  getScreenshot() {
    return new Promise((resolve, reject) => {
      this.bimsyncViewer3d.viewer("screenshot", (it) => {
        return it == null || it.trim() === "" || !it.trim().startsWith("data:image") ? reject(it) : resolve(it)
      })
    })
  }

  onObjectsSelected(selectedObjects: string[]) {
    this.bimsyncApi.currentSelectedBimsyncObjectIds$.next(selectedObjects || [])
  }

  afterViewer3dLoad() {
    this.bimsyncViewer3d.viewer("modelInfo", (modelInfos) => {
      this.updateModelVisibility(modelInfos.map((model) => ({ ...model, isVisible: model.id === this.currentModel.id })))

      const model = modelInfos.find((m) => m.id === this.currentModel.id)
      if (model && model.boundingBox) {
        this.bimsyncViewer3d.viewer("lookAtBoundingBox", model.boundingBox)
      }
    })

    this.bimsyncViewer3d.bind("viewer.select", (event, selected) => {
      this.bimsyncViewer3d.viewer("viewpoint", null, (viewpoint) => {
        this.objectsSelected.emit({ objects: selected, viewpoint })
      })
    })
  }

  setViewpoint(viewpoint: any) {
    this.bimsyncViewer3d.viewer("viewpoint", viewpoint)
  }

  deselectAll() {
    this.bimsyncViewer3d.viewer("deselectAll")
  }

  makeAllObjectsTranslucent() {
    this.bimsyncViewer3d.viewer("translucentAll")
  }

  makeAllObjectsOpaque() {
    this.bimsyncViewer3d.viewer("opaqueAll")
  }

  makeTheseObjectsTheOnlyOpaqueOnes(objectIds: string[]) {
    this.makeAllObjectsTranslucent()
    this.bimsyncViewer3d.viewer("opaque", objectIds)
  }

  select(objectId: string) {
    this.bimsyncViewer3d.viewer("select", objectId)
  }

  onViewer3dError(errorType: any) {
    console.error("onViewer3dError", errorType)
  }

  updateModelVisibility(models: Partial<IBimsyncModelData>[]) {
    // Returns if the viewer hasn't been properly instantiated yet
    if (this.bimsyncViewer3d == null) {
      return
    }

    models.forEach((model) => {
      this.bimsyncViewer3d.viewer(model.isVisible ? "showModel" : "hideModel", model.id)
    })
  }
}

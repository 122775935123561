<div>
  <checkd-bimsync-storey-list
    [class.hidden]="panelSelection != 'storey' || containerCollapsed"
    [bimsyncStoreys]="bimsyncApi.currentBimsyncProjectModelStoreys$ | async"
    (selectStorey)="bimsyncApi.currentSelectedStorey$.next($event)">
  </checkd-bimsync-storey-list>

  <checkd-bimsync-model-info
    [class.hidden]="panelSelection != 'info' || containerCollapsed"
    [bimsyncModel]="bimsyncApi.currentBimsyncProjectModel$ | async">
  </checkd-bimsync-model-info>


  <div class="bottom-actions" [class.no-padding-top]="containerCollapsed" fxLayout="row" fxLayoutAlign="none center">

    <div class="drag-handle" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon cdkDragHandle>open_with</mat-icon>
    </div>

    <button mat-icon-button (click)="viewerDirectionHorizontal = !viewerDirectionHorizontal">
      <mat-icon>{{viewerDirectionHorizontal ? 'swap_vert' : 'swap_horiz'}}</mat-icon>
    </button>

    <span fxFlex="1 1 0"></span>

    <mat-button-toggle-group multiple>
      <mat-button-toggle
        [checked]="viewer3dVisible"
        (click)="toggleMapsShown('3d')">
        <mat-icon>3d_rotation</mat-icon>
      </mat-button-toggle>

      <mat-button-toggle
        [checked]="viewer2dVisible"
        (click)="toggleMapsShown('2d')">
        <mat-icon>map</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <span fxFlex="2 2 0"></span>

    <button mat-icon-button
            (click)="panelSelectionButtonClicked('storey')"
            [class.selected]="panelSelection == 'storey' && !containerCollapsed">
      <mat-icon>layers</mat-icon>
    </button>
<!--    <button mat-icon-button-->
<!--            (click)="panelSelectionButtonClicked('info')"-->
<!--            [class.selected]="panelSelection == 'info' && !containerCollapsed">-->
<!--      <mat-icon>info</mat-icon>-->
<!--    </button>-->
  </div>
</div>

// @ts-nocheck
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { CheckdColors } from "@checkd-colors"
import { Item } from "@models/common/item"
import { convertWorkflowStateToStatus } from "@models/common/workflow"
import { WorkflowParentStates } from "@models/common/workflow.interface"
import { BimsyncApiV2Service } from "@services/apis/bimsync-api-v2.service"
import { TopicItemService } from "@services/topic-item.service"
import { BehaviorSubject, combineLatest, Subscription } from "rxjs"
import { distinctUntilChanged } from "rxjs/operators"
import { BimsyncViewService } from "../../services/bimsync-view.service"

declare const bimsync: any
declare const $: any

export const STATUS_COLOR_MAPPING = {
  [WorkflowParentStates.OPEN]: CheckdColors.CHECKD_GRAY,
  [WorkflowParentStates.DELEGATED]: CheckdColors.CHECKD_RED,
  [WorkflowParentStates.INPROGRESS]: CheckdColors.CHECKD_YELLOW,
  [WorkflowParentStates.FIXED]: CheckdColors.CHECKD_BLUE,
  [WorkflowParentStates.CLOSED]: CheckdColors.CHECKD_GREEN,
}

@Component({
  selector: "checkd-bimsync-viewer2d",
  templateUrl: "./bimsync-viewer2d.component.html",
  styleUrls: ["./bimsync-viewer2d.component.scss"],
})
export class BimsyncViewer2dComponent implements AfterViewInit, OnDestroy {
  @Input() viewer2dId: string = "bimsync-viewer-2d"
  @Input() subscribeToModelItems: boolean = true
  @Input() subscribeToSingleItem: boolean = false
  @Input() markerClickActive: boolean = true
  @Output() viewerReady = new EventEmitter()

  bimsyncViewer2d
  subscriptions: Subscription[] = []

  viewerReady$ = new BehaviorSubject(false)

  constructor(
    public bimsyncApi: BimsyncApiV2Service,
    private bimsyncViewService: BimsyncViewService,
    private topicItemService: TopicItemService
  ) {
    this.viewerReady.emit(false)
  }

  ngAfterViewInit(): void {
    this.setupSubscriptions()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.bimsyncApi.currentViewer2dUrl$.subscribe((url) => this.loadViewer2d(url)),

      this.bimsyncApi.currentSelectedStorey$.subscribe((storey) => {
        this.updateCurrentStorey(storey)
      }),

      combineLatest([this.viewerReady$, this.bimsyncViewService.currentModelItems$]).subscribe(([viewerReady, items]) => {
        if (!viewerReady || !this.subscribeToModelItems) {
          return
        }
        this.bimsyncViewer2d.viewer2d("clearMarkers")
        for (const item of items) {
          this.addItemMarker(item)
        }
      }),
      combineLatest([
        this.viewerReady$.pipe(distinctUntilChanged()),
        this.topicItemService.currentItem$.pipe(distinctUntilChanged((p: Item, q: Item) => p.uid === q.uid && p.status === q.status)),
      ]).subscribe(([viewerReady, currentItem]) => {
        if (viewerReady && currentItem != null && this.subscribeToSingleItem) {
          this.addItemMarker(currentItem)
        }
      }),
    ]
  }

  loadViewer2d(viewerUrl: string) {
    bimsync.setOnViewer2dLoadCallback(() => {
      this.bimsyncViewer2d = $(`#${this.viewer2dId}`)
      this.bimsyncViewer2d.viewer2d("loadUrl", viewerUrl)

      this.bimsyncViewer2d.bind("viewer2d.markerclick", (event, marker) => {
        if (this.markerClickActive) {
          this.bimsyncViewService.currentItemUid$.next(marker.id)
        }
      })

      this.bimsyncViewer2d.bind("loaded", (event) => {
        const storeys = this.bimsyncViewer2d.viewer2d("getStoreys") || []
        if (storeys.length > 0) {
          this.bimsyncViewer2d.viewer2d("showStorey", storeys[0].id)
          this.bimsyncApi.currentSelectedStorey$.next(storeys[0])
        }
        this.bimsyncApi.currentBimsyncProjectModelStoreys$.next(storeys)
        this.viewerReady$.next(true)
        this.viewerReady.emit(true)
      })
    })
    bimsync.loadViewer2d()
  }

  select(ids: string[]) {
    this.bimsyncViewer2d.viewer2d("lookAt", ids)
  }

  setViewpointVisibility(visible: boolean) {
    return visible ? this.bimsyncViewer2d.viewer2d("showViewpoint") : this.bimsyncViewer2d.viewer2d("hideViewpoint")
  }

  private updateCurrentStorey(storey) {
    // Returns if the 2D viewer hasn't been properly instantiated yet
    if (this.bimsyncViewer2d == null) {
      return
    }
    this.bimsyncViewer2d.viewer2d("hideStoreys")
    this.bimsyncViewer2d.viewer2d("showStorey", storey.id)
  }

  private getMarkerIconUrlFromItem(item: Item) {
    switch (convertWorkflowStateToStatus(item.status)) {
      case "OPEN":
        return "assets/open.png"
      case "DELEGATED":
        return "assets/delegated.png"
      case "FIXED":
        return "assets/fixed.png"
      case "INPROGRESS":
        return "assets/inprogress.png"
      case "CLOSED":
        return "assets/closed.png"
      default:
        return "assets/open.png"
    }
  }

  public addItemMarker(item: Item) {
    this.bimsyncViewer2d.viewer2d("addMarker", {
      id: item.uid,
      x: item.positionX,
      y: item.positionY,
      data: {},
      clickable: true,
      draggable: false,
      icon: {
        url: this.getMarkerIconUrlFromItem(item),
        size: {
          width: 1,
          height: 1,
        },
        anchor: {
          x: 26,
          y: 74,
        },
      },
    })
  }
}

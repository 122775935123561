import { Component, OnDestroy, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { CompanyFeatures, FilestackUploadResult, Person } from "@models/common"
import { CompanyService, FilestackService, FirebaseAuthService, SnackbarService, UserService } from "@services"
import firebase from "firebase/compat/app"
import { MessageService } from "primeng/api"
import { combineLatest, Observable, Subscription } from "rxjs"
import { ICheckdAgreement } from "@models/common/agreements.interface"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { OAuthService } from "angular-oauth2-oidc"
import { map, take } from "rxjs/operators"
import { DialogService } from "../../dialogs/dialog.service"
import { SsoActivationSuccessDialogComponent } from "../../dialogs/sso-activation-success-dialog/sso-activation-success-dialog.component"

@Component({
  selector: "profile-view",
  templateUrl: "./profile-view.component.html",
  styleUrls: ["./profile-view.component.scss"],
})
export class ProfileViewComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription()

  public CompanyFeatures = CompanyFeatures
  public defaultEmailForm: UntypedFormGroup

  public hasMicrosoftLogin$: Observable<boolean> = this.userService.providerData$.pipe(
    map((providerData) => providerData!.find((d) => d!.providerId === "microsoft.com") != null)
  )

  constructor(
    public userService: UserService,
    public companyService: CompanyService,
    private db: AngularFirestore,
    private filestackService: FilestackService,
    private snackbar: SnackbarService,
    public oAuthService: OAuthService,
    private authService: FirebaseAuthService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      combineLatest([this.userService.providerData$, this.userService.currentUser$]).subscribe(([providerData, currentUser]) =>
        // @ts-ignore
        this.setupDefaultEmailForm(providerData, currentUser)
      )
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  private setupDefaultEmailForm(userInfo: firebase.UserInfo[], currentUser: Person) {
    // No reason to set up/display default email form if there's only one provider
    if ((userInfo || []).length < 2) {
      // @ts-ignore
      this.defaultEmailForm = undefined

      return
    }

    this.defaultEmailForm = this.fb.group({
      selectedEmail: [currentUser.email, [Validators.required]],
    })
  }

  async updateDefaultEmailButtonPressed(user: Person) {
    await user.ref!.update({ email: this.defaultEmailForm.get("selectedEmail")!.value })

    this.snackbar.showMessage("Default email successfully updated")
  }

  savePersonal(personalData: any) {
    this.userService.updateCurrentUser(personalData)
  }

  saveSettings(settings: any) {
    this.userService.updateCurrentUser({
      notifications: {
        assignedTaskMail: settings.assignedTaskMail,
      },
    })
  }

  async updateAgreements(agreements: ICheckdAgreement[]) {
    await this.userService.updateUserAgreements(agreements)
    this.snackbar.showMessage(`Thank you for accepting our terms and agreements!`)
  }

  initBimsyncLoginFlow() {
    this.oAuthService.initLoginFlow("", { prompt: "None" })
  }

  async uploadProfileImage() {
    const result = await this.filestackService.pick({ storeTo: { location: "gcs", path: "avatars/" } })

    return this.handleFilesUpload(result)
  }

  private handleFailedFilesUpload(failedFiles: any) {
    // TODO implement
    console.error("Failed upload:", failedFiles)
  }

  private handleFilesUpload(uploadResult: FilestackUploadResult) {
    if (uploadResult.filesFailed != null && uploadResult.filesFailed.length > 0) {
      return this.handleFailedFilesUpload(uploadResult.filesFailed)
    }

    if (uploadResult.filesUploaded != null && uploadResult.filesUploaded.length > 0) {
      return this.userService.updateCurrentUser({ image: uploadResult.filesUploaded[0].url })
    }
  }

  public async toggleMicrosoftSSO(user: Person, providerData: firebase.UserInfo[]) {
    try {
      const hasMicrosoftLogin = await this.hasMicrosoftLogin$.pipe(take(1)).toPromise()

      if (hasMicrosoftLogin) {
        let dialogResult = await this.dialogService.confirm(
          "Unlink Microsoft account",
          "Are you sure you want to deactivate and unlink Microsoft account? This cannot be undone and you won't be able to login again in this account with Microsoft!",
          false,
          true
        )

        if (dialogResult) await this.authService.unlinkAccount("microsoft.com")
      } else {
        await this.authService.linkAccount("microsoft.com")
      }

      if (hasMicrosoftLogin) {
        // set user's default email back to mail address associated with their regular account if their default was the SSO email
        const correctEmail = providerData.find((provider) => provider.providerId === "password")?.email

        if (correctEmail && correctEmail !== user.email) {
          await user.ref!.update({ email: correctEmail })
        }
      }

      if (!hasMicrosoftLogin) {
        await this.dialogService.openDialog(SsoActivationSuccessDialogComponent)
      }

      this.messageService.add({
        severity: "success",
        summary: `Microsoft login ${hasMicrosoftLogin ? "Deactivated" : "Activated"}`,
        detail: `Your account has been successfully ${hasMicrosoftLogin ? "Deactivated" : "Activated"}`,
      })
    } catch (e) {
      this.messageService.add({
        severity: "error",
        summary: "Unable to link account",
        detail: "An error happened while attempting to modify your login methods. Please contact support.",
      })
    }
  }
}
